import React from "react";
import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./RightBar.module.css";
import Search from "./Search";
import Login from "./Login";
import { UserProvider } from "./UserContext";
import {
  FaInfoCircle,
  FaUserFriends,
  FaLayerGroup,
  FaStar,
  FaHome,
  FaEnvelope,
  FaSearch,
} from "react-icons/fa";
import { UserContext } from "./UserContext";

const RightBar = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const { userId, showLoginPopup, setShowLoginPopup } = useContext(UserContext);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Check if the window width is less than 768px on mount
    if (window.innerWidth <= 800) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [navigate]);

  useEffect(() => {
    async function fetchUsers() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_featured_raters?user_id=${userId}`
        );
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    }

    fetchUsers();
  }, []); // Empty dependency array, only run once on mount.

  const handleFollow = async (profileId) => {
    // Update the local state first to reflect the change optimistically
    if (!userId) {
      setShowLoginPopup(true);
      return;
    }



    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.user_id === profileId ? { ...user, is_following: true } : user
      )
    );

    // Define the payload
    const payload = {
      user_id: userId,
      profile_id: profileId,
      relationship: "Following",
    };

    // Send the POST request
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/add_usertag`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      // Check if the POST request was successful
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // You may want to do something with the response here
      const data = await response.json();
      console.log(data); // For debugging

      // Optionally, you could confirm the change happened on the backend
      // and update the state again if necessary
    } catch (error) {
      console.error("Failed to send follow", error);

      // If the POST failed, revert the optimistic update
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.user_id === profileId ? { ...user, is_following: false } : user
        )
      );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <Search
          className={styles.search}
          onProductClick={(product) => {
            navigate(`/products/${product.product_id}`);
          }}
        />
      </div>

      <div className={styles.addContainer}>
        {/* <div onClick={()=> navigate("/SubmitProduct")} className={styles.newProduct}>Add New Product</div> */}
        <div onClick={()=> navigate("/SubmitProduct")}  className={styles.addText}>
          {" "}
          Can't find what you are looking for?<br/> Click here to add a new product.
        </div>
      </div>

      <div className={styles.featuredRaters}>
        <h2>Top Raters</h2>
        <div className={styles.raters}>
          {users.slice(0, 5).map((user) => (
            <div key={user.user_id} className={styles.rater}>
              <Link to={`/profiles/${user.user_id}`}>
                <img
                  src={user.photo_url}
                  alt={user.username}
                  className={styles.raterImage}
                />
              </Link>
              <div className={styles.raterInfo}>
                <span className={styles.raterName}>@{user.username}</span>
                <span className={styles.raterRating}>
                  {user.rating_count} Ratings
                </span>
                <span className={styles.raterRating}>
                  {user.similarity ? `${user.similarity}% Similarity` : ""}
                </span>
              </div>

              {user.is_following && user.follows_you && (
                <div className={styles.followContainer}>Friends</div>
              )}

              {user.is_following && !user.follows_you && (
                <div className={styles.followContainer}>Following</div>
              )}

              {!user.is_following && user.user_id != userId && (
                <div
                  onClick={() => handleFollow(user.user_id)}
                  className={styles.followContainer}
                >
                  Follow
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className={styles.footer}>
        <p>© Rated App Limited 2024</p>
        <a href="/PrivacyPolicy" className={styles.privacy}>
          Privacy Policy
        </a>

        <a href="/Welcome" className={styles.privacy}>
          Landing
        </a>

        {/* <a href="/Blog" className={styles.privacy}>
          Blog
        </a> */}


        <a href="mailto:ratedlimited@gmail.com" className={styles.contact}>
          Contact Us
        </a>

        
      </div>

    </div>
  );
};

export default RightBar;
