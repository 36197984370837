import React, { useState, useRef, useContext } from "react";
import Modal from "react-modal";
import GiveRating from "./GiveRating";
import ReviewModal from "../components/ReviewModal";
// import ShareModal from '../components/ShareModal';
import { formatDistanceToNow } from "date-fns";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; // Default styles
import "slick-carousel/slick/slick-theme.css";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faEye,
  faHandPointer,
  faComment,
  faThumbsUp,
  faReply,
  faPaperPlane,
  faRightFromBracket,
  faStar,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./RatingsGallery.module.css";
import { Link } from "react-router-dom";
import { UserContext } from "../components/UserContext";
import ReportModal from "./ReportModal";
import { useNavigate } from "react-router-dom";

const RatingsGallery = ({
  ratings,
  loading,
  userId,
  handleRatingChange,
  loadMoreRows,
  unknownImg, // Assuming unknownImg is a valid URL or local asset
  username: propUsername = "",
  photoUrl: propPhotoUrl = "",
  showTitle = false,
  refreshRatings = () => {},
  handleLike = () => {},
  hasMore = true,
  profile_id = null,
  setRatings = () => {},
  setRatingsRerender = () => {},
  profilePhoto = null,
  profileName = null,
  screen = "gallery",
}) => {
  const timeoutRef = useRef(null);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [comment, setComment] = useState("");
  const [modalProduct, setModalProduct] = useState(null);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [newComment, setNewComment] = useState("");
  const { username, photoUrl } = useContext(UserContext);
  const [showReportModal, setShowReportModal] = useState(false);
  const [reportProductId, setReportProductId] = useState(null);
  const [reportUserId, setReportUserId] = useState(null);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [copyMessage, setCopyMessage] = useState("Copied to clipboard");
  const navigate = useNavigate();
  const [activeModal, setActiveModal] = useState(null);
  const [copyId, setCopyId] = useState(null);
  const ratingsDescriptions = [
    { num: -3, desc: "Blocked" },
    { num: -2, desc: "Skipped" },
    { num: -1, desc: "Wishlist" },
    { num: 0, desc: "Not Seen" },
    { num: 1, desc: "Awful" },
    { num: 2, desc: "Poor" },
    { num: 3, desc: "Underwhelming" },
    { num: 4, desc: "Mediocre" },
    { num: 5, desc: "Decent" },
    { num: 6, desc: "Good" },
    { num: 7, desc: "Very Good" },
    { num: 8, desc: "Great" },
    { num: 9, desc: "Incredible" },
    { num: 10, desc: "The Best" },
  ];

  const getRatingDescription = (rating) => {
    const item = ratingsDescriptions.find((desc) => desc.num === rating);
    return item ? item.desc : "Select a Rating"; // Default text
  };

  const copyToClipboard = (item) => {
    const profileUrl = `https://www.rated.fun/profiles/${
      item.user_id || profile_id || userId
    }/rating/${item.rating_id}`;
    navigator.clipboard
      .writeText(profileUrl)
      .then(() => {
        setShowCopyMessage(true);
        setTimeout(() => setShowCopyMessage(false), 2000); // Message disappears after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
    setActiveModal(null);
    setCopyId(item.rating_id);
  };

  const handleSettingsClose = () => {
    setShowSettingsModal(false);
  };

  const toggleModal = (ratingId) => {
    setActiveModal(activeModal === ratingId ? null : ratingId);
  };

  const handleReportProduct = (item) => {
    setShowReportModal(true);
    setReportProductId(item.product_id);
    setReportUserId(item.user_id);
  };

  const closeReportModal = () => {
    setShowReportModal(false);
    setReportProductId(null);
    setReportUserId(null);
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  const Carousel = ({ item }) => {
    const [active, setActive] = useState(0);

    const handleSlideChange = (index) => {
      setActive(index);
    };

    const sliderSettings = {
      ...settings,
      afterChange: handleSlideChange, // Updates active index
    };

    return (
      <div className={styles.carouselContainer}>
        <div className={styles.pagination}>
          {item.user_images.map((_, index) => (
            <span
              key={`dot-${item.product_id}-${index}`}
              className={index === active ? styles.dotActive : styles.dot}
            >
              •
            </span>
          ))}
        </div>
        <Slider {...sliderSettings}>
          {item.user_images.map((image, index) => (
            <div
              key={`image-${item.product_id}-${index}`}
              onDoubleClick={() => {
                navigate(`/products/${item.product_id}`);
              }}
              onAuxClick={(event) => {
                if (event.button === 1) {
                  navigate(`/products/${item.product_id}`);
                }
              }}
            >
              <img
                src={image}
                alt={`Slide ${index}`}
                style={{
                  width: "100%",
                  height: "300px",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
              />
            </div>
          ))}
        </Slider>
      </div>
    );
  };

  const handleCommentAdd = (ratingId, newCommentText, parent_id = null) => {
    setRatingsRerender(false);
    setComment(""); // Clear the comment input field after adding a comment
    setNewComment(""); // Clear the new comment input field after adding a comment
    // Prevent mutating the original ratings state directly
    let updatedRatings = ratings.map((rating) => ({ ...rating }));

    if (!parent_id) {
      // Adding a new top-level comment
      updatedRatings = updatedRatings.map((rating) => {
        if (rating.rating_id === ratingId) {
          let newComment = {
            comment_id: Math.random().toString(36).substr(2, 9), // Generates a pseudo-random ID
            user_id: userId,
            comment: newCommentText,
            datetime: new Date().toISOString(),
            username: username,
            photo_url: photoUrl,
            parent_id: null,
            child_comments: [],
            show_child_comments: false,
          };
          return {
            ...rating,
            comments: [...rating.comments, newComment],
            num_comments: rating.num_comments + 1,
            show_comments: true,
          };
        }
        return rating;
      });
    } else {
      // Adding a reply to an existing comment
      updatedRatings = updatedRatings.map((rating) => {
        if (rating.rating_id === ratingId) {
          return {
            ...rating,
            comments: rating.comments.map((comment) => {
              if (comment.comment_id === parent_id) {
                let newComment = {
                  comment_id: Math.random().toString(36).substr(2, 9), // Generates a pseudo-random ID
                  user_id: userId,
                  comment: newCommentText,
                  datetime: new Date().toISOString(),
                  username: "test",
                  photo_url: "test",
                  parent_id: parent_id,
                  child_comments: [],
                  show_child_comments: false,
                };
                return {
                  ...comment,
                  child_comments: [...comment.child_comments, newComment],
                };
              }
              return comment;
            }),
          };
        }
        return rating;
      });
    }

    // Update the state with the modified ratings array.
    setRatings(updatedRatings);
  };

  // Determine which username and photoUrl to use
  const finalUsername = propUsername || profileName || "test";
  const finalPhotoUrl = propPhotoUrl || profilePhoto || "test";

  const [selectedRater, setSelectedRater] = useState(null);
  const [selectedSharer, setSelectedSharer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const addInteraction = (ratingId) => {
    const payload = {
      rating_id: ratingId,
    };

    fetch(`${process.env.REACT_APP_BACKEND_URL}/add_interaction`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).catch((error) => console.error("Error adding interaction:", error));
  };

  const handleModalSubmit = () => {
    setModalProduct("");
    setIsModalOpen(false);
  };

  if (ratings.length === 0 && loading) {
    // <LoadingSpinner />
    return null;
  }

  const renderHeader = () => {
    // return showTitle ? <Text style={styles.feedTitle}>Your Feed</Text> : null;
    return null;
  };

  const renderFooter = () => (
    <div>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <span>Loading...</span>
        </div>
      ) : !hasMore ? (
        <div className={styles.caughtUpView}>
          <p className={styles.caughtUpText}>
            You are all caught up!
            <br />
            <br />
            Try changing your user or category filters to discover more content.
          </p>
        </div>
      ) : null}
    </div>
  );

  const onClose = () => {
    setShowCommentModal(false);
    setModalProduct(null);
  };

  const handleCommentSubmit = (ratingId, comment, parent_id = null) => {
    if (!comment || comment.trim() === "") {
      console.log("Comment is empty");
      return;
    }

    const payload = {
      rating_id: ratingId,
      comment: comment,
      user_id: userId,
      timestamp: new Date().toISOString(),
      parent_id: parent_id,
    };

    try {
      fetch(`${process.env.REACT_APP_BACKEND_URL}/add_comment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          // Assuming handleCommentAdd is a function that needs to be called after the fetch operation is successful
          handleCommentAdd(ratingId, comment, parent_id);
          return response.json();
        })
        .then((data) => {
          // You can use the response data here
          console.log("Comment added:", data);
        })
        .catch((error) => {
          console.error("Error adding comment:", error);
        });
    } catch (error) {
      console.error("Error adding comment:", error);
    }
    setModalProduct(null); // Make sure this function is defined and used correctly
  };

  const commentModal = (item) => {
    return (
      <div className={styles.centeredView}>
        <div className={styles.centeredView} onClick={onClose}>
          <div
            className={styles.modalOverlay}
            onClick={(e) => e.stopPropagation()} // Prevents click inside the modal from closing it
          >
            <button className={styles.cancelButton} onClick={onClose}>
              X
            </button>

            <div className={styles.commentHeader}>
              <img
                src={photoUrl}
                alt="User"
                className={styles.userPhotoModal}
              />
              <div className={styles.userDetails}>
                <span className={styles.userName}>{username}</span>
              </div>
            </div>

            {item && (
              <textarea
                placeholder={`Write a comment about ${
                  item.username || finalUsername || "their"
                }'s rating of ${item.product_name || "..."}...`}
                className={styles.commentInput}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            )}
            <button
              className={styles.submitButton}
              onClick={() => {
                handleCommentSubmit(item.rating_id, comment);
                setShowCommentModal(false);
                setComment("");
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  };

  const handleCommentPress = (item) => {
    setRatingsRerender(false);
    if (item.num_comments === 0 && userId && userId > 0) {
      setModalProduct(item);
      setShowCommentModal(true);
    } else {
      const updatedRatings = ratings.map((rating) => {
        if (rating.rating_id === item.rating_id) {
          return {
            ...rating,
            show_comments: !rating.show_comments,
          };
        }
        return rating;
      });

      setRatings(updatedRatings);
    }
  };

  const showReplies = (rating_id, comment_id) => {
    console.log(rating_id, comment_id);
    setRatingsRerender(false);
    const updatedRatings = ratings.map((rating) => {
      if (rating.rating_id === rating_id) {
        return {
          ...rating,
          comments: rating.comments.map((comment) => {
            if (comment.comment_id === comment_id) {
              return {
                ...comment,
                show_child_comments: !comment.show_child_comments,
              };
            }
            return comment;
          }),
        };
      }
      return rating;
    });

    setRatings(updatedRatings);
  };

  const LoadingSpinner = () => <div className={styles.purpleSpinner}></div>;

  return (
    <div>
      {isModalOpen && selectedRater && (
        <ReviewModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedRater(null); // Reset the selected rater data on close
            addInteraction(selectedRater.rating_id);
          }}
          productName={selectedRater.product_name}
          secondaryInfo={selectedRater.secondary_info}
          userPhoto={selectedRater.user_photo || finalPhotoUrl}
          username={selectedRater.username || finalUsername}
          onSubmit={handleModalSubmit}
          userId={userId}
          type={"other"}
          productId={selectedRater.product_id}
          review={selectedRater.review}
          rating={selectedRater.rating}
          categoryIcon={selectedRater.category_photo}
          timestamp={selectedRater.date}
          likes={selectedRater.likes}
          views={selectedRater.views}
          interactions={selectedRater.interactions}
          raterId={selectedRater.user_id}
        />
      )}

      {showReportModal && (
        <ReportModal
          isOpen={showReportModal}
          onClose={closeReportModal}
          onSubmit={closeReportModal}
          product_id={reportProductId}
          profile_id={reportUserId}
        />
      )}

      {/* {isShareModalVisible && selectedSharer && (
      <div>
        Test
      </div>
      <ShareModal
        isVisible={isShareModalVisible}
        onClose={() => {
          setIsShareModalVisible(false);
          setSelectedSharer(null); // Reset the selected sharer data on close
          addInteraction(selectedSharer.rating_id);
        }}
        friendsList={friendsList}
        navigation={navigation}
        shareMessage={
          userId !== null &&
          userId > 0 &&
          (userId === selectedSharer.user_id || userId === profile_id)
            ? `https://www.rated.fun/profiles/${
                selectedSharer.user_id || profile_id
              }/rating/${selectedSharer.rating_id} I rated ${
                selectedSharer.product_name
              } ${selectedSharer.rating}/10`
            : `https://www.rated.fun/profiles/${
                selectedSharer.user_id || profile_id
              }/rating/${selectedSharer.rating_id} ${
                selectedSharer.username || finalUsername
              } rated ${selectedSharer.product_name} ${
                selectedSharer.rating
              }/10`
        }
        type={
          userId !== null &&
          userId > 0 &&
          (userId === selectedSharer.user_id || userId === profile_id)
            ? `Your Rating of ${selectedSharer.product_name}`
            : `${selectedSharer.username || finalUsername}'s Rating of ${
                selectedSharer.product_name
              }`
        }
        userId={userId}
        selectedSharer={selectedSharer}
        refreshRatings={refreshRatings}
      />
    )} */}

      {showCommentModal && modalProduct && commentModal(modalProduct)}

      <InfiniteScroll
        className={styles.container}
        dataLength={ratings.length}
        next={loadMoreRows}
        hasMore={hasMore}
        loader={<LoadingSpinner />}
        endMessage={
          <p style={{ textAlign: "center", color: "white" }}>
            <b>
              You have seen all matching ratings. Try adjusting your filters to
              explore more content!
            </b>
          </p>
        }
        refreshFunction={refreshRatings}
      >
        {ratings.map((item) => (
          <div key={item.rating_id} className={styles.ratingCard}>
            <div className={styles.cardMain}>
              <div className={styles.cardLeft}>
                {item.user_images[0] ? (
                  <Carousel item={item} /> // Assuming Carousel is a separate component you have created
                ) : (
                  <button
                    className={styles.photoButton}
                    onClick={() => {
                      addInteraction(item.rating_id);
                      // history.push(`/product/${item.product_id}`);
                    }}
                  >
                    <Link
                      to={`/products/${item.product_id}`}
                      onClick={() => addInteraction(item.rating_id)}
                      onAuxClick={(event) => {
                        if (event.button === 1) {
                          // 1 is for middle mouse button
                          addInteraction(item.rating_id);
                        }
                      }}
                    >
                      <img
                        src={item.product_photo || unknownImg}
                        alt="Product"
                        className={styles.productPhoto}
                      />
                    </Link>
                  </button>
                )}
              </div>

              <div className={styles.cardMiddle}>
                <div className={styles.userDetails}>
                  <button
                    className={styles.photoButton}
                    onClick={() => {
                      addInteraction(item.rating_id);
                      if (item.user_id !== userId && item.user_id) {
                        // history.push(`/userprofile/${item.user_id}`);
                      }
                    }}
                  >
                    <Link
                      to={
                        item.user_id
                          ? `/profiles/${item.user_id}`
                          : profile_id
                          ? `/profiles/${profile_id}`
                          : `/MyProfile`
                      }
                    >
                      <img
                        src={item.user_photo || profilePhoto || finalPhotoUrl}
                        alt="User"
                        className={styles.userPhoto}
                        onClick={(e) => {
                          addInteraction(item.rating_id);
                        }}
                      />
                    </Link>
                    <div className={styles.userName}>
                      {item.username || profileName || finalUsername}
                      <span className={styles.ratingDate}>
                        {formatDistanceToNow(new Date(item.date), {
                          addSuffix: true,
                        }).replace("about ", "")}
                      </span>
                    </div>
                  </button>
                </div>
                <div className={styles.ratingScore}>
                  <FontAwesomeIcon
                    icon={faStar}
                    className={styles.ratingStar}
                  />
                  {item.rating === -2
                    ? "X"
                    : item.rating === -1
                    ? "!"
                    : item.rating === -3
                    ? "-"
                    : item.rating}

                  <span className={styles.ratingDescription}>
                    {getRatingDescription(item.rating)}
                  </span>
                </div>

                <div className={styles.productContainer}>
                  <Link
                    to={`/products/${item.product_id}`}
                    className={styles.productTitle}
                  >
                    <span className={styles.productTitleText}>
                      {item.product_name}
                    </span>

                    <div className={styles.categoryInfo}>
                      <img
                        src={item.category_photo}
                        alt="Category"
                        className={styles.categoryPhoto}
                      />

                      {item.secondary_info && ` ${item.secondary_info}`}
                    </div>
                  </Link>
                  {item.parent_name && (
                    <span className={styles.productParentText}>
                      From {item.parent_name}
                    </span>
                  )}
                </div>
              </div>

              <div className={styles.cardRight}>
                {activeModal === item.rating_id && (
                  <div>
                    <div
                      className={styles.modalContainer}
                      onClick={() => setActiveModal(null)}
                    ></div>
                    <div className={styles.modalBody}>
                      <div className={styles.optionText}>
                        <h3
                          className={styles.optionText}
                          onClick={() => copyToClipboard(item)}
                        >
                          Share Rating
                        </h3>
                      </div>
                      {userId > 0 && (
                        <div className={styles.optionText}>
                          <h3
                            className={styles.optionText}
                            onClick={() => handleReportProduct(item)}
                          >
                            Report Rating
                          </h3>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div
                  className={styles.shareContainer}
                  onClick={() => toggleModal(item.rating_id)}
                >
                  <FontAwesomeIcon
                    icon={faEllipsisVertical}
                    className={styles.shareIcon}
                  />
                </div>
                {showCopyMessage && item.rating_id == copyId && (
                  <div className={styles.copyMessage}>{copyMessage}</div>
                )}

                <div className={styles.cardFooterRight}>
                  <GiveRating
                    productId={item.product_id}
                    productTitle={item.product_name}
                    userRating={item.user_rating}
                    userId={userId}
                    handleRating={handleRatingChange}
                    categoryId={item.category_id}
                    screen={screen}
                  />
                </div>

                <div
                  className={styles.ratingStats}
                  onClick={() => handleCommentPress(item)}
                >
                  <FontAwesomeIcon
                    icon={faComment}
                    className={styles.ratingIcon3}
                    color={item.num_comments > 0 ? "rgb(86, 0, 107)" : "grey"}
                  />
                  {item.num_comments > 0 && (
                    <span className={styles.ratingStatText}>
                      {item.num_comments}
                    </span>
                  )}
                </div>
              </div>
            </div>

            {item.review && (
              <div
                className={styles.ratingCardReview}
                onClick={() => {
                  setSelectedRater(item);
                  setIsModalOpen(true);
                }}
              >
                <span className={styles.ratingReview}>{item.review}</span>
              </div>
            )}
            {/* 
            {item.review && (
              <div className={styles.ratingLikes}>
                <FontAwesomeIcon icon={faHeart} className={styles.ratingIcon} />
                <span className={styles.ratingStatText}>{item.likes}</span>
              </div>
            )} */}

            {/* <div className={styles.cardFooter}>
              <div className={styles.cardFooterLeft}>
                <div className={styles.ratingStats}>
                  <span className={styles.textSmall}>Views</span>
                  <FontAwesomeIcon icon={faEye} className={styles.ratingIcon} />
                  <span className={styles.ratingStatText}>{item.views}</span>
                </div>
                <div className={styles.ratingStats}>
                  <span className={styles.textSmall}>Clicks</span>
                  <FontAwesomeIcon
                    icon={faHandPointer}
                    className={styles.ratingIcon}
                  />
                  <span className={styles.ratingStatText}>
                    {item.interactions}
                  </span>
                </div>

                {item.review || item.user_images[0] ? (
                  <div
                    className={styles.ratingStats}
                    onClick={() => handleLike(userId, item)}
                  >
                    <span className={styles.textSmall}>Likes</span>
                    <FontAwesomeIcon
                      icon={faThumbsUp}
                      className={styles.ratingIcon2}
                      color={item.user_liked ? "rgb(86, 0, 107)" : "grey"}
                    />
                    <span className={styles.ratingStatText}>{item.likes}</span>
                  </div>
                ) : null}
              </div>
            </div> */}

            {item.show_comments && (
              <div className={styles.commentsContainer}>
                {item.comments.map((comment) => (
                  <div key={comment.comment_id} className={styles.commentCard}>
                    <div className={styles.commentSectionLeft}>
                      <Link to={`/profiles/${comment.user_id}`}>
                        <img
                          src={comment.photo_url}
                          alt={`${comment.username}'s avatar`}
                          className={styles.commentPhoto}
                        />
                      </Link>
                    </div>
                    <div className={styles.commentSectionRight}>
                      <div className={styles.commentDetails}>
                        <span className={styles.commentName}>
                          {comment.username}
                        </span>
                        <div>
                          <span className={styles.commentDate}>
                            {formatDistanceToNow(new Date(comment.datetime), {
                              addSuffix: true,
                            }).replace("about ", "")}
                          </span>
                          <button
                            className={styles.sendIcon}
                            onClick={() =>
                              console.log(
                                "Show replies for",
                                comment.comment_id
                              )
                            }
                          >
                            <div>
                              <span className={styles.replyCountText}>
                                {comment.child_comments.length}
                              </span>
                              <FontAwesomeIcon
                                icon={faReply}
                                className={styles.replyIcon}
                                size={18}
                              />
                            </div>
                          </button>
                        </div>
                      </div>
                      <span className={styles.commentMainText}>
                        {comment.comment}
                      </span>
                      {comment.show_child_comments && (
                        <div>
                          {comment.child_comments.map((child) => (
                            <div
                              key={child.comment_id}
                              className={styles.commentCard}
                            >
                              <div className={styles.commentSectionLeftChild}>
                                <Link to={`/profiles/${item.user_id}`}>
                                  <img
                                    src={child.photo_url}
                                    alt={`${child.username}'s avatar`}
                                    className={styles.commentPhoto}
                                  />
                                </Link>
                              </div>
                              <div className={styles.commentSectionRightChild}>
                                <div className={styles.commentDetails}>
                                  <span className={styles.commentName}>
                                    {child.username}
                                  </span>
                                  <span className={styles.commentDate}>
                                    {formatDistanceToNow(
                                      new Date(child.datetime),
                                      {
                                        addSuffix: true,
                                      }
                                    ).replace("about ", "")}
                                  </span>
                                </div>
                                <span className={styles.commentMainText}>
                                  {child.comment}
                                </span>
                              </div>
                            </div>
                          ))}
                          <div className={styles.commentCard}>
                            <textarea
                              placeholder="Write a reply..."
                              className={styles.newCommentInput}
                              value={newComment}
                              onChange={(e) => setNewComment(e.target.value)}
                            />
                            <button
                              className={styles.sendIcon}
                              onClick={() =>
                                handleCommentSubmit(
                                  item.rating_id,
                                  newComment,
                                  comment.comment_id
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faPaperPlane}
                                className={styles.submitIconChild}
                                size={18}
                              />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                {userId && userId > 0 && (
                  <div key="new-comment-input" className={styles.commentCard}>
                    <div className={styles.commentSectionLeft}>
                      <img
                        src={photoUrl}
                        alt="User Avatar"
                        className={styles.commentPhoto}
                      />
                    </div>
                    <div className={styles.commentSectionRight}>
                      <div className={styles.commentDetails}>
                        <span className={styles.commentName}>{username}</span>
                      </div>
                      <textarea
                        placeholder="Write a comment..."
                        className={styles.newCommentInput}
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                      />
                    </div>
                    <button
                      className={styles.sendIcon}
                      onClick={() =>
                        handleCommentSubmit(item.rating_id, newComment)
                      }
                    >
                      <FontAwesomeIcon
                        icon={faPaperPlane}
                        className={styles.submitIcon}
                        size={18}
                      />
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default RatingsGallery;
