import React, { useContext } from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import BuddyFinder from "./pages/BuddyFinder";
import CategoryPage from "./pages/CategoryPage";
import RatingPage from "./pages/RatingPage";
import Header from "./components/Header";
import ProductPage from "./pages/ProductPage";
import UserPage from "./pages/UserPage";
import SubmitProduct from "./pages/SubmitProduct";
import ExploreCategories from "./pages/ExploreCategories";
import { UserContext, UserProvider } from "./components/UserContext";
import Register from "./pages/Register";
import MyProfile from "./pages/MyProfile";
import About from "./pages/About";
import ExploreUsers from "./pages/ExploreUsers";
import EditProfile from "./pages/EditProfile";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import LandingMobile from "./pages/LandingMobile";
import LandingWeb from "./pages/LandingWeb";
import LeftBar from "./components/LeftBar";
import RightBar from "./components/RightBar";
import TopBar from "./components/TopBar";
import BottomBar from "./components/BottomBar";
import YourNetwork from "./pages/YourNetwork";
import YourRatings from "./pages/YourRatings";
import RapidRate from "./pages/RapidRate";
import Notifications from "./pages/Notifications";
import Messages from "./pages/Messages";
import DirectMessage from "./pages/DirectMessage";
import EditProduct from "./pages/EditProduct";
import ScrollToTop from "./ScrollToTop";
import ImportRatings from "./pages/ImportRatings";
import ExternalProfile from "./pages/ExternalProfile";
import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";

import "./index.css";

const App = () => {
  const { userId } = useContext(UserContext);

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          {/* <Route path="/" element={userId ? <LandingWeb /> : <LandingWeb />} /> */}
          <Route path="/WelcomeM" element={<LandingMobile />} />
          <Route path="/Welcome" element={<LandingWeb />} />
          <Route path="/Welcome/:username" element={<LandingWeb />} />
          <Route
            path="*"
            element={
              <>
                {/* <Header /> */}
                <LeftBar />
                <RightBar />
                <Routes>
                  <Route path="/" element={<RatingPage />} />
                  <Route path="/RatingPage" element={<RatingPage />} />
                  <Route
                    path="/ExploreCategories"
                    element={<ExploreCategories />}
                  />
                  {/*<Route path="/BuddyFinder" element={<BuddyFinder />} />*/}
                  <Route path="/ExploreUsers" element={<ExploreUsers />} />
                  <Route
                    path="/products/:product_id"
                    element={<ProductPage />}
                  />
                  <Route path="/profiles/:profile_id" element={<UserPage />} />
                  <Route path="/ExternalProfile/:profile_id" element={<ExternalProfile />} />
                  <Route
                    path="/profiles/:profile_id/rating/:rating_id"
                    element={<UserPage />}
                  />
                  <Route
                    path="/categories/:category_id"
                    element={<CategoryPage />}
                  />
                  <Route
                    path="/categories/:category_id/:keyword_id"
                    element={<CategoryPage />}
                  />
                  <Route
                    path="/EditProduct/:product_id"
                    element={<EditProduct />}
                  />
                  <Route path="/SubmitProduct" element={<SubmitProduct />} />
                  <Route path="/Register" element={<Register />} />
                  <Route path="/MyProfile/" element={<MyProfile />} />
                  <Route path="/MyProfile/:rating_id" element={<MyProfile />} />
                  <Route path="/About" element={<About />} />
                  <Route path="/EditProfile" element={<EditProfile />} />
                  <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                  <Route path="/YourRatings" element={<YourRatings />} />
                  <Route path="/YourNetwork" element={<YourNetwork />} />
                  <Route path="/RapidRate" element={<RapidRate />} />
                  <Route path="/Notifications" element={<Notifications />} />
                  <Route path="/Messages" element={<Messages />} />
                  <Route path="/ImportRatings" element={<ImportRatings />} />
                  <Route path="/Blog" element={<Blog />} />
                  <Route path="/BlogPost/:id" element={<BlogPost />} />
                  <Route
                    path="/DirectMessage/:user2Id"
                    element={<DirectMessage />}
                  />
                  {/* ... other routes */}
                </Routes>
              </>
            }
          />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserProvider>
    <App />
  </UserProvider>
);
