import React, { useState, useEffect, useRef, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faStar,
  faXmarkCircle,
  faBan,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./GiveRating.module.css";
import ProductImageUpload from "./ImageUploadMultiple";
import { UserContext } from "./UserContext"; // adjust the path if needed

const RatingPopup = ({
  visible,
  setModalVisible,
  handleRating,
  productTitle,
  userRating,
  productId,
  categoryId,
  userId,
  screen = "unknown",
}) => {
  const [selectedRating, setSelectedRating] = useState(userRating);
  const starContainerRef = useRef(null);
  const [hoverRating, setHoverRating] = useState(0);
  const [review, setReview] = useState("");
  const { showLoginPopup, setShowLoginPopup, ratingCount, setRatingCount } =
    useContext(UserContext);
  const ratingsDescriptions = [
    { num: -3, desc: "Blocked" },
    { num: -2, desc: "Skipped" },
    { num: -1, desc: "Wishlist" },
    { num: 0, desc: "Not Seen" },
    { num: 1, desc: "Awful" },
    { num: 2, desc: "Poor" },
    { num: 3, desc: "Underwhelming" },
    { num: 4, desc: "Mediocre" },
    { num: 5, desc: "Decent" },
    { num: 6, desc: "Good" },
    { num: 7, desc: "Very Good" },
    { num: 8, desc: "Great" },
    { num: 9, desc: "Incredible" },
    { num: 10, desc: "The Best" },
  ];

  useEffect(() => {
    setSelectedRating(userRating);
  }, [userRating]);

  const getRatingDescription = (rating) => {
    const item = ratingsDescriptions.find((desc) => desc.num === rating);
    return item ? item.desc : "Select a Rating"; // Default text
  };

  const handleSelectRating = (rating, review = false) => {
    if (!userId) {
      setModalVisible(false);
      setShowLoginPopup(true);
      return;
    }

    if (rating > 0) {
      setRatingCount(ratingCount + 1);
    }

    const date = new Date(); // Get the current date and time
    date.setHours(date.getHours() + 1); // Add one hour to the current time
    const payload = {
      product_id: productId,
      user_id: userId,
      rating: rating,
      timestamp: date.toISOString(),
      category_id: categoryId,
      review: review,
      screen: screen,
      device: "web",
    };

    fetch(`${process.env.REACT_APP_BACKEND_URL}/input_rating`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    // Call handleSelectRating here if you want to auto-submit on click
    handleRating(productId, rating);
    setModalVisible(false);
    // Redirect or further actions
  };

  const updateRating = () => {
    // Use hoverRating if it's valid; otherwise, use selectedRating
    const newRating = hoverRating > 0 ? hoverRating : selectedRating;
    setSelectedRating(newRating);
  };

  const renderStars = () => {
    let stars = [];
    for (let i = 1; i <= 10; i++) {
      stars.push(
        <div
          key={i}
          className={`${styles.star} ${
            i <= (hoverRating || selectedRating) ? styles.highlightedStar : ""
          }`}
          onClick={updateRating}
          onMouseEnter={() => setHoverRating(i)}
          onMouseLeave={() => setHoverRating(0)}
          style={{
            color:
              i <= (hoverRating || selectedRating)
                ? "purple"
                : "rgb(230,230,230)",
          }}
        >
          <FontAwesomeIcon icon={faStar} size="3x" />
        </div>
      );
    }
    return stars;
  };

  const onClose = (event) => {
    event.stopPropagation();
    setSelectedRating(userRating);
    setModalVisible(false);
  };

  if (!visible) return null;

  return (
    <div className={styles.modal} onClick={onClose}>
      <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
        <FontAwesomeIcon
          icon={faXmarkCircle}
          className={styles.closeIcon}
          onClick={onClose}
        />

        <h1 className={styles.selectedRating}>
          {hoverRating ||
            (selectedRating === -1
              ? "!"
              : selectedRating === -2
              ? "X"
              : selectedRating === -3
              ? "-"
              : selectedRating) ||
            "?"}
        </h1>
        <p className={styles.ratingDescription}>
          {getRatingDescription(hoverRating || selectedRating)}
        </p>
        <p className={styles.ratingTitle}>{productTitle}</p>

        <div className={styles.starsContainer} ref={starContainerRef}>
          {renderStars()}
        </div>

        <div className={styles.ratingOptions}>
          <div className={styles.wishlistContainer}>
            <FontAwesomeIcon
              icon={faBan}
              className={styles.skip}
              onClick={() => {
                handleSelectRating(-2);
              }}
            />
            <p className={styles.wishlistText}>Skip</p>
          </div>
          <button
            onClick={() => {
              handleSelectRating(selectedRating, review);
            }}
          >
            <h3 className={styles.submitText}>Submit Rating</h3>
          </button>
          <div className={styles.wishlistContainer}>
            <FontAwesomeIcon
              icon={faCircleCheck}
              className={styles.wishlist}
              onClick={() => {
                handleSelectRating(-1);
              }}
            />
            <p className={styles.wishlistText}>Wishlist</p>
          </div>
        </div>

        <div className={styles.reviewContainer}>
          <textarea
            className={styles.review}
            placeholder="After giving a rating write a review!"
            value={review}
            onChange={(e) => setReview(e.target.value)}
          />
          <div className={styles.imageContainer}>
            <ProductImageUpload
              onFileUploaded={() => {
                window.alert("Images uploaded!");
              }}
              userId={userId}
              product_id={productId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const GiveRating = ({
  productId,
  userRating,
  productTitle,
  userId,
  categoryId,
  handleRating,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { showLoginPopup, setShowLoginPopup, ratingCount, setRatingCount } =
  useContext(UserContext);

  const toggleModal = (event) => {
    event.stopPropagation(); // Stop event bubbling at this level
    setModalVisible(!modalVisible);
  };

  const handleClick = (event) => {
    event.stopPropagation(); // This stops the event from bubbling up
    if (!userId) {
      setModalVisible(false);
      setShowLoginPopup(true);
      return;
    } // Show login popup if user is not logged in
    setModalVisible(true);
  };

  return (
    <div className={styles.ratingContainer} onClick={handleClick}>
      {userRating ? (
        <div className={styles.yourRatingContainer}>
          <div className={styles.ratingText}>
            {userRating === -1
              ? "!"
              : userRating === -2
              ? "X"
              : userRating === -3
              ? "-"
              : userRating}
          </div>
          <p className={styles.yourRating}>Your Rating</p>
        </div>
      ) : (
        <p className={styles.rateText}>Rate</p>
      )}

      <button onClick={toggleModal} className={styles.pencilContainer}>
        <FontAwesomeIcon icon={faStar} className={styles.pencil} />
      </button>
      <RatingPopup
        visible={modalVisible}
        setModalVisible={setModalVisible}
        productTitle={productTitle}
        userRating={userRating}
        productId={productId}
        categoryId={categoryId}
        handleRating={handleRating}
        userId={userId}
      />
    </div>
  );
};

export default GiveRating;
