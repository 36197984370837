import React, { createContext, useState, useEffect } from "react";

// Create a Context
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(localStorage.getItem("userId") || null);
  const [username, setUsername] = useState(
    localStorage.getItem("username") || ""
  );
  const [photoUrl, setPhotoUrl] = useState(
    localStorage.getItem("photoUrl") || ""
  );
  const [notificationCount, setNotificationCount] = useState(0);
  const [messageCount, setMessageCount] = useState(0);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [followingCount, setFollowingCount] = useState(0);
  const [ratingCount, setRatingCount] = useState(0);
  const [userType, setUserType] = useState("New");
  const [productCount, setProductCount] = useState(-1);
  const [productIds, setProductIds] = useState([]);

  useEffect(() => {
    if (userId) {
      localStorage.setItem("userId", userId);
      localStorage.setItem("username", username);
      localStorage.setItem("photoUrl", photoUrl);
    } else {
      localStorage.removeItem("userId");
      localStorage.removeItem("username");
      localStorage.removeItem("photoUrl");
    }
  }, [userId]);

  return (
    <UserContext.Provider
      value={{
        userId,
        setUserId,
        username,
        setUsername,
        photoUrl,
        setPhotoUrl,
        notificationCount,
        setNotificationCount,
        messageCount,
        setMessageCount,
        showLoginPopup,
        setShowLoginPopup,
        followingCount,
        setFollowingCount,
        ratingCount,
        setRatingCount,
        userType,
        setUserType,
        productCount,
        setProductCount,
        productIds,
        setProductIds,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
