import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "./UserContext"; // adjust the path if needed
import { Link, useNavigate } from "react-router-dom"; // Assuming you're using react-router for navigation
import "./Login.css";
import {
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebaseConfig"; // Make sure to import auth correctly
import styles from "./Login.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignIn,
  faXmarkCircle,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  faApple,
  faGoogle,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

function Login() {
  {
    /*const [username, setUsername] = useState(""); */
  }
  const [password, setPassword] = useState("");
  const [signedIn, setSignedIn] = useState(false);
  const { navigate } = useNavigate();
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const [errorMessage, setErrorMessage] = useState(null);
  const {
    userId,
    setUserId,
    username,
    setUsername,
    photoUrl,
    setPhotoUrl,
    showLoginPopup,
    setShowLoginPopup,
  } = useContext(UserContext);
  // const [showLoginPopup, setShowLoginPopup] = useState(false); // New State for Popup visibility
  const [enterLogin, setEnterLogin] = useState(false);

  const GoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      // Handle the sign-in result here if needed
      const user = result.user;
      // Register the user in the backend if needed
      registerUserInBackend({
        username: user.displayName.replace(" ", ""),
        email: user.email,
        firstName: user.displayName?.split(" ")[0],
        lastName: user.displayName?.split(" ").slice(1).join(" "),
        photoUrl: user.photoURL,
        unique_id: user.uid,
      });
    } catch (error) {
      console.error("Google sign-in error:", error);
      setErrorMessage("Google sign-in failed. Please try again.");
    }
  };

  const FacebookSignIn = async () => {
    const provider = new FacebookAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      registerUserInBackend({
        username: user.displayName,
        email: user.email,
        firstName: user.displayName?.split(" ")[0],
        lastName: user.displayName?.split(" ").slice(1).join(" "),
        photoUrl: user.photoURL,
        unique_id: user.uid,
      });
    } catch (error) {
      console.error("Facebook sign-in error:", error);
      setErrorMessage("Facebook sign-in failed. Please try again.");
    }
  };

  const AppleSignIn = async () => {
    const provider = new OAuthProvider("apple.com");
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      registerUserInBackend({
        username: user.displayName,
        email: user.email,
        firstName: user.displayName?.split(" ")[0],
        lastName: user.displayName?.split(" ").slice(1).join(" "),
        photoUrl: user.photoURL,
        unique_id: user.uid,
      });
    } catch (error) {
      console.error("Apple sign-in error:", error);
      setErrorMessage("Apple sign-in failed. Please try again.");
    }
  };

  const registerUserInBackend = async ({
    username,
    email,
    firstName,
    lastName,
    photoUrl,
    unique_id,
  }) => {
    const payload = {
      username,
      email,
      firstName,
      lastName,
      photoUrl,
      unique_id,
      timestamp: new Date().toISOString(),
    };

    try {
      if (signedIn) return; // Prevent double execution when user is already signed in
      const response = await fetch(`${apiUrl}/add_user_auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      // Check if the backend responded with an error status
      if (data && data.status === "error") {
        // Display the error message in an alert
        window.alert("Registration Failed", data.message);
        return; // Early return to prevent further execution
      }

      if (data.user_id) {
        setUserId(data.user_id);
        setUsername(data.username);
        setPhotoUrl(data.photo_url);

        // Optionally, navigate to another screen or perform additional actions
        navigate("/RapidRate", {
          state: {
            tutorial: true, // Example state value
          },
        });
      }
    } catch (error) {
      console.error("Failed to register user in backend:", error);
      // Optionally, handle error appropriately, e.g., show a generic error message
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User is signed in", user);
        const { displayName, email, photoURL, uid } = user;

        let firstName = "";
        let lastName = "";
        let username = "";

        if (displayName) {
          const nameParts = displayName.trim().split(" ");
          firstName = nameParts[0];
          lastName = nameParts.slice(1).join(" ") || "";
          username = displayName.replace(/\s+/g, "");
          username = username.length > 14 ? username.slice(0, 14) : username;
        } else {
          username = email.split("@")[0];
          username = username.length > 14 ? username.slice(0, 14) : username;
        }

        // Using logical OR to handle cases where username might be null or undefined
        registerUserInBackend({
          username: username || "",
          email: email || "",
          firstName: firstName || "",
          lastName: lastName || "",
          photoUrl: photoURL || "",
          unique_id: uid || "",
        });

        // Handle signed-in user information
      } else {
        console.log("No user is signed in");
        // Handle cases where there is no user signed in
      }
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault(); // Prevent the form from causing a page reload

    const payload = {
      username,
      password,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/user_login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();

      if (data.user_id === -1) {
        setErrorMessage("Username not found.");
      } else if (data.user_id === -2) {
        setErrorMessage("Incorrect password. Click here to reset.");
      } else {
        setUserId(data.user_id); // Assuming user_id is part of the returned data.
        setPhotoUrl(data.photo_url);
        setShowLoginPopup(false); // Close the popup
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  const toggleLoginPopup = () => {
    setErrorMessage(null); // Clear any previous error messages
    setEnterLogin(false); // Reset the login form
    setShowLoginPopup(!showLoginPopup);
  };

  const handleLogout = () => {
    setUserId(null); // Reset user context
    setUsername("");
    setPhotoUrl("");
  };

  return (
    <div>
      {!userId ? (
        <div className={styles.loginText} onClick={toggleLoginPopup}>
          <FontAwesomeIcon icon={faSignIn} className={styles.loginIcon} />
          Login
        </div>
      ) : (
        <>
          <div className={styles.usernameText}>
            <Link to="/MyProfile">
              <img
                src={photoUrl}
                alt="Profile"
                className={styles.loginProfilePic}
                title="My Account"
              />
            </Link>
            <span className={styles.navText}>{username}</span>
          </div>
          {/*
          <div className="logoutText" onClick={handleLogout}>
            [Logout]
      </div> */}
        </>
      )}

      {showLoginPopup && (
        <>
          <div className={styles.overlay} onClick={toggleLoginPopup}></div>
          <div className={styles.loginPopup}>
            <h1 className={styles.headerTitle}>Sign Up to Rated</h1>
            <FontAwesomeIcon
              icon={faXmarkCircle}
              className={styles.closeIcon}
              onClick={toggleLoginPopup}
            />
            {enterLogin && (
              <FontAwesomeIcon
                icon={faArrowLeft}
                className={styles.leftIcon}
                onClick={() => setEnterLogin(false)}
              />
            )}
            <form onSubmit={handleLogin}>
            {!enterLogin && (
  <div className={styles.buttonContainer}>
    {/* Google Sign-In */}
    <div className={styles.googleContainer} onClick={GoogleSignIn}>
      <img
        src={
          "https://s3.eu-west-2.amazonaws.com/rated.images/google_logo.png"
        }
        alt="Google Login"
        className={styles.loginLogo}
      />
      <span className={styles.googleText}>
        Continue with Google
      </span>
    </div>

    {/* Apple Sign-In */}
    <div className={styles.appleContainer}>
      <img
        src={
          "https://s3.eu-west-2.amazonaws.com/rated.images/apple_logo.png"
        }
        alt="Apple Login"
        className={styles.loginLogo}
      />
      <span className={styles.appleText}>
        Continue with Apple
      </span>
    </div>

    {/* OR with horizontal lines */}
    <div className={styles.orContainer}>
      <hr className={styles.line} />
      <span className={styles.orText}>or</span>
      <hr className={styles.line} />
    </div>

    {/* Create an Account */}
    <Link to="/Register" className={styles.register_link}>
      <button
        className={styles.loginButton}
        onClick={toggleLoginPopup}
      >
        Create an Account
      </button>
    </Link>

    {/* Already have an account? */}
    <div
      className={styles.alreadyText}
      onClick={() => setEnterLogin(true)}
    >
      <p>Already have an account? Click here to login.</p>
    </div>
  </div>
)}

              {enterLogin && (
                <div className={styles.loginEntry}>
                  <label>
                    <input
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Username"
                    />
                  </label>
                  <br />
                  <label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                    />
                  </label>
                  <br />

                  {errorMessage && (
                    <p className={styles.errorText}>{errorMessage}</p>
                  )}

                  <button className={styles.loginButton2} type="submit">
                    Login
                  </button>
                  <div className={styles.alreadyText}>
                    <p>
                      Forgot your username or password? Click here to reset.
                    </p>
                  </div>
                </div>
              )}
            </form>
          </div>
        </>
      )}
    </div>
  );
}

export default Login;
