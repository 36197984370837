import React, { useState, useEffect } from "react";
import styles from "./LandingWeb.module.css";
import { useNavigate, useParams } from "react-router-dom"; // Assuming you're using react-router v6 for navigation

const LandingWeb = () => {
  const { username } = useParams();
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const screenshots = [
    "https://s3.eu-west-2.amazonaws.com/rated.images/screenshot1.PNG",
    "https://s3.eu-west-2.amazonaws.com/rated.images/screenshot2.PNG",
    "https://s3.eu-west-2.amazonaws.com/rated.images/screenshot3.PNG",
    "https://s3.eu-west-2.amazonaws.com/rated.images/screenshot4.PNG",
    "https://s3.eu-west-2.amazonaws.com/rated.images/screenshot5.PNG",
    "https://s3.eu-west-2.amazonaws.com/rated.images/screenshot6.PNG",
    "https://s3.eu-west-2.amazonaws.com/rated.images/screenshot7.PNG",
    //"https://s3.eu-west-2.amazonaws.com/rated.images/screenshot8.PNG",
  ];

  const captions = [
    "Rate everything in one place",
    "Rapidly rate products in your categories",
    "Add friends and see who has similar taste",
    "Build your profile, network and wishlist",
    "View recent ratings from your friends",
    "Get a personal prediction for each product",
    "Discover top rated and popular content",
    //"Use filters to find exactly what you need",
  ];

  const categories = [
    "Restaurants",
    "Music",
    "Movies",
    "Books",
    "TV Shows",
    "Board Games",
    "Hotels",
    "Escape Rooms",
    "Video Games",
    "Youtube Channels",
    "Documentaries",
    "Massage & Spa",
    "Museums",
    "Anime",
    "Theatre",
    "Fast Food",
    "Podcasts",
    "Gadgets",
    "Albums",
    "Other",
  ];

  const categoryIds = [6, 5, 0, 8, 3, 18, 17, 25, 2, 16, 21, 23, 24, 20, 19,26,22,11,27,13];

  const categoryImages = [
    "https://s3.eu-west-2.amazonaws.com/rated.images/restaurants_icon.png",
    "https://s3.eu-west-2.amazonaws.com/rated.images/music_icon.jpg",
    "https://s3.eu-west-2.amazonaws.com/rated.images/film_icon.png",
    "https://s3.eu-west-2.amazonaws.com/rated.images/books_icon2.png",
    "https://s3.eu-west-2.amazonaws.com/rated.images/tv_icon.png",
    "https://s3.eu-west-2.amazonaws.com/rated.images/board_games_icon.png",
    "https://s3.eu-west-2.amazonaws.com/rated.images/hotel_img.png",
    "https://s3.eu-west-2.amazonaws.com/rated.images/escape_icon.png",
    "https://s3.eu-west-2.amazonaws.com/rated.images/video_games_icon.png",
    "https://s3.eu-west-2.amazonaws.com/rated.images/youtube_icon2.png",
    "https://s3.eu-west-2.amazonaws.com/rated.images/documentary_icon.png",
    "https://s3.eu-west-2.amazonaws.com/rated.images/massage_icon.png",
    "https://s3.eu-west-2.amazonaws.com/rated.images/museum_icon.png",
    "https://s3.eu-west-2.amazonaws.com/rated.images/anime_icon.png",
    "https://s3.eu-west-2.amazonaws.com/rated.images/theater_icon4.png",
    "https://s3.eu-west-2.amazonaws.com/rated.images/fast_food_icon.png",
    "https://s3.eu-west-2.amazonaws.com/rated.images/podcast_icon3.png",
    "https://s3.eu-west-2.amazonaws.com/rated.images/gadgets_icon.png",
    "https://s3.eu-west-2.amazonaws.com/rated.images/album_icon.png",
    "https://s3.eu-west-2.amazonaws.com/rated.images/other_icon.png",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        // Adjust this value as needed
        setShowButtons(true);
      } else {
        setShowButtons(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % screenshots.length);
    }, 3000); // Rotate every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (username) {
      try {
        const payload = {
          username: username,
        };

        fetch(`${apiUrl}/add_referral`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
      } catch (error) {
        console.error("Error adding referral:", error);
      }
    }

    // Check if the window width is less than 768px on mount
    if (window.innerWidth < 1000) {
      navigate("/WelcomeM"); // Assuming '/mobile' is the route for LandingMobile
    }
  }, [navigate]);

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img
          src="https://s3.eu-west-2.amazonaws.com/rated.images/rated_logo_6bg.png"
          alt="Logo"
          className={styles.logoImage}
        />

        {showButtons && (
          <div
            className={`${styles.buttonContainer2} ${
              showButtons ? styles.buttonContainer2Visible : ""
            }`}
          >
            <div
              className={styles.websiteLink2}
              onClick={() => navigate("/RatingPage")}
            >
              {" "}
              Proceed to Website{" "}
            </div>
            <a href="https://play.google.com/store/apps/details?id=com.rated_native2&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                className={styles.badgeImage2}
              />
            </a>
            <a href="https://apps.apple.com/us/app/rated/id6476522766">
              <img
                src="https://s3.eu-west-2.amazonaws.com/rated.images/apple_badge.png"
                alt="Apple Store"
                className={styles.badgeImageApple2}
              />
            </a>
          </div>
        )}

        <div className={styles.socialLinks}>
          {/* <a href="https://www.instagram.com/kansaschickenandpizza/?hl=en/">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/600px-Instagram_icon.png"
              alt="Instagram"
              className={styles.socialIcon}
            />
          </a> */}

          <a href="https://discord.gg/ZgWYJXNKKe">
            <img
              src="https://s3.eu-west-2.amazonaws.com/rated.images/discord_logo.png"
              alt="Discord"
              className={styles.socialIcon}
            />
          </a>
          <a href="https://twitter.com/RatedApp_">
            <img
              src="https://s3.eu-west-2.amazonaws.com/rated.images/X_logo.png"
              alt="Twitter"
              className={styles.socialIcon}
            />
          </a>

          <a href="https://www.linkedin.com/in/raza-sekha-118720109/">
            <img
              src="https://s3.eu-west-2.amazonaws.com/rated.images/linkedin_logo.png"
              alt="LinkedIn"
              className={styles.socialIcon}
            />
          </a>

          {/* <a href="https://discord.gg/6pu9qDkD">
            <img
              src="https://s3.eu-west-2.amazonaws.com/rated.images/facebook_logo.png"
              alt="Facebook"
              className={styles.socialIcon}
            />
          </a> */}
          <a href="https://www.youtube.com/channel/UCHVwvvfsmzAFLHUMmD-TiKA">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/e/ef/Youtube_logo.png"
              alt="Youtube"
              className={styles.socialIcon}
            />
          </a>
          <a href="https://www.tiktok.com/@rated.app">
            <img
              src="https://s3.eu-west-2.amazonaws.com/rated.images/tiktok_logo2.png"
              alt="TikTok"
              className={styles.socialIcon}
            />
          </a>
        </div>
      </div>
      <div className={styles.page}>
        <div className={styles.left}>
          <div className={styles.textContainer}>
            <p className={styles.headCaption}>
              Rate Everything
              <br />
              Share With Friends <br />
              Discover the Best Content
              <br />
            </p>
            <p className={styles.subCaption}>
              Rated is a new social media platform for sharing ratings and
              discovering content across a range of categories{" "}
              {/* Tired of picking the wrong movie, restaurant or game? Want a new way to discover content?
              Learn from your network and use our personalised predictions to make the best choices. */}
            </p>
          </div>

          <div className={styles.buttonContainer}>
            <div
              className={styles.websiteLink}
              onClick={() => navigate("/RatingPage")}
            >
              {" "}
              Proceed to Website{" "}
            </div>
            <a href="https://play.google.com/store/apps/details?id=com.rated_native2&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                alt="Get it on Google Play"
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                className={styles.badgeImage}
              />
            </a>
            <a href="https://apps.apple.com/us/app/rated/id6476522766">
              <img
                src="https://s3.eu-west-2.amazonaws.com/rated.images/apple_badge.png"
                alt="Apple Store"
                className={styles.badgeImageApple}
              />
            </a>
          </div>
          {/* <div className={styles.textContainer}>
            <p className={styles.notes}>
              Completely Free, No Ads, No Data Selling
            </p>
          </div> */}
          <div className={styles.categoryGallery}>
            {categories.map((category, index) => (
              <div
                key={index}
                className={styles.categoryContainer}
                onClick={() => navigate(`/categories/${categoryIds[index]}`)}
              >
                <img
                  src={categoryImages[index]}
                  alt={`Category ${category}`}
                  className={styles.categoryImage}
                />
                {/* <h2 className={styles.categoryText}>{category}</h2> */}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.right}>
          <img
            src="https://s3.eu-west-2.amazonaws.com/rated.images/rated_screenshot_pair.png"
            alt="Rated Screenshots"
            className={styles.mainScreenshot}
          />
        </div>
      </div>

      <div className={styles.information}>
        <div className={styles.infoContainer}>
          <div className={styles.infoTitle}>
            Social Media & Smart Content Discovery in One Central Platform
          </div>
          <div className={styles.infoMain}>
            <div className={styles.infoImage}>
              <img
                src="https://s3.eu-west-2.amazonaws.com/rated.images/friends_screenshot.png"
                alt="Similarity Score"
              />
              <p className={styles.imageCaption}>
                Get a similarity score with every other user showing how similar
                your taste is.
              </p>
            </div>
            <div className={styles.infoImage}>
              <img
                src="https://s3.eu-west-2.amazonaws.com/rated.images/user_screenshot.png"
                alt="Similarity Score"
              />
              <p className={styles.imageCaption}>
                Head to a profile to see their ratings, stats and similarity
                with you for each category.
              </p>
            </div>
            <div className={styles.infoImage}>
              <img
                src="https://s3.eu-west-2.amazonaws.com/rated.images/feed_screenshot.png"
                alt="Similarity Score"
              />
              <p className={styles.imageCaption}>
                Receive a customisable feed of recent ratings and reviews from
                your network.
              </p>
            </div>
          </div>
        </div>

        <div className={styles.infoContainerNew}>
          <div className={styles.infoLeft}>
            <div className={styles.infoImage4}>
              <img
                src="https://s3.eu-west-2.amazonaws.com/rated.images/rated_screenshot_pair2.png"
                alt="Similarity Score"
              />
            </div>
          </div>

          <div className={styles.infoRight}>
            <p className={styles.imageCaption2}>
              Rated offers the smartest way to find the best content for you.
              <br />
              <br />
              Data science is used to give you a personal prediction of how much
              you would like each product.
              <br />
              <br />
              Your similarity scores with other users are used to weight their
              ratings in the prediction.
              <br />
              <br />
              The more you rate, the better your predictions will become.
              <br />
              <br />
            </p>
          </div>
        </div>

        <div className={styles.infoContainer}>
          <div className={styles.infoMain}>
            <div className={styles.infoImage2}>
              <img
                src="https://s3.eu-west-2.amazonaws.com/rated.images/filter_new_screenshot.PNG"
                alt="Similarity Score"
              />
              <p className={styles.imageCaption}>
                Use a range of filters to find exactly what you are after
              </p>
            </div>
            <div className={styles.infoImage2}>
              <img
                src="https://s3.eu-west-2.amazonaws.com/rated.images/tags_screenshot.PNG"
                alt="Similarity Score"
              />
              <p className={styles.imageCaption}>
                Add tags to users to view ratings from specific groups of people
              </p>
            </div>
            <div className={styles.infoImage2}>
              <img
                src="https://s3.eu-west-2.amazonaws.com/rated.images/wordcloud_example.PNG"
                alt="Similarity Score"
              />
              <p className={styles.imageCaption}>
                Generate cool visualsations of your ratings and share online
              </p>
            </div>
          </div>
        </div>

        <div className={styles.infoContainer}>
          <div className={styles.infoTitle}>Our Core Values</div>


          <div className={styles.values}>

          <div className={styles.valueContainer}>
            <div className={styles.valueTitle}>Transparency</div>
            <div className={styles.valueDescription}>
              The methodology for predicted ratings, similarity scores and
              rankings will always be publicly available. Any sponsored content
              will be clearly marked.
            </div>
          </div>

          <div className={styles.valueContainer}>
            <div className={styles.valueTitle}>Rating Integrity</div>
            <div className={styles.valueDescription}>
              We will do our best to ensure that all ratings are genuine and not
              manipulated. Any suspicious activity or accounts will have their
              ratings removed from public view. Only accounts with a minimum
              number of ratings will be used in average ratings and predictions.
            </div>
          </div>

          <div className={styles.valueContainer}>
            <div className={styles.valueTitle}>Best Predictions</div>
            <div className={styles.valueDescription}>
              Our primary goal is to help you find the content which you will
              love the most. We will not let any other factors influence our
              predicted ratings and we will strive to improve our accuracy.
            </div>
          </div>

          </div>
        </div>
        {/* <img
                src="https://s3.eu-west-2.amazonaws.com/rated.userphotos/f02f5ce6-22b9-4d4e-bcb5-9d207a3ac548.jpg"
                alt="Raza Sekha"
                className={styles.profileImage}
              /> */}
        <div className={styles.infoContainer}>
          <div className={styles.infoMain}>

            <div className={styles.infoText}>
              Rated is currently a one man project! I am a
              mechanical engineer turned data scientist and now (trying to be)
              an entrepreneur.
              <br />
              <br />
              I truly believe that Rated has potential to be the next big social platform and actually help people choose the best content.
              <br />
              <br />
              It would be great to hear from you, for feedback or even for a chat.
              Please feel free to reach out to me via email or any of the social
              media links above.
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <p>© Rated App Limited 2024</p>
        <a href="/PrivacyPolicy" className={styles.privacy}>
          Privacy Policy
        </a>
        <a href="mailto:ratedlimited@gmail.com" className={styles.contact}>
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default LandingWeb;
