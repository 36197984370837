import React from "react";
import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./LeftBar.module.css";
import Search from "./Search";
import Login from "./Login";
import {
  FaInfoCircle,
  FaUserFriends,
  FaLayerGroup,
  FaStar,
  FaHome,
  FaEnvelope,
  FaSearch,
  FaCog,
  FaBell,
  FaScroll,
  FaUsers,
} from "react-icons/fa";
import { UserContext } from "./UserContext";

const LeftBar = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const { userId,photoUrl, notificationCount, setNotificationCount, messageCount, setMessageCount, username, setUsername, setPhotoUrl, setUserType, setRatingCount, setFollowingCount } =
    useContext(UserContext);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;


  useEffect(() => {
    // Check if the window width is less than 768px on mount
    if (window.innerWidth <= 800) {
      setIsMobile(true);
      navigate("/LandingMobile");
    } else {
      setIsMobile(false);
    }
  }, [navigate]);

  useEffect(() => {
    // Function to fetch notification count
    const fetchNotificationCount = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/get_notification_count?userId=${userId}`,
        );
        const notifications = await response.json();
        setMessageCount(notifications.total_unread);
        setNotificationCount(notifications.notification_count);
        console.log('Notification count:', notifications.notification_count);
        console.log('Message count:', notifications.total_unread);
      } catch (error) {
        console.error('Error fetching notification count:', error);
      }
    };
  
    // Function to fetch user data
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/get_launch_info?user_id=${userId}`
        );
        const data = await response.json();
        setRatingCount(data.rating_count || 0);
        setFollowingCount(data.following_count || 0);
        if (data.photo_url) setPhotoUrl(data.photo_url);
        if (data.username) setUsername(data.username);
        if (data.user_type) setUserType(data.user_type);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
  
    // Fetch notifications and user data if userId is available
    if (userId) {
      fetchNotificationCount();
      fetchUserData();
    }
  
  }, [userId]);

  return (
    <div className={styles.container}>
      <Link to="/RatingPage">
      <div className={styles.logo}>
        <img
          src="https://s3.eu-west-2.amazonaws.com/rated.images/rated_logo_6bg.png"
          alt="Logo"
          className={styles.logoImage}
        />
      </div>
      </Link>

      <div className={styles.navigation}>
        {userId === null ? (
          <div className={styles.profile}>
            <Login />
          </div>
        ) : (
          <Link to={`/profiles/${userId}`} className={styles.navItem}>
            <img
              className={styles.navPhoto}
              src={photoUrl}
              alt={"user_photo"}
            />
            <span className={styles.navText}>{username || 'Profile'}</span>
          </Link>
        )}
        <Link to="/RatingPage" className={styles.navItem}>
          <FaHome className={styles.navIcon} />
          <span className={styles.navText}>Home</span>
        </Link>

        {/* <Link to="/RatingPage" className={styles.navItem}>
          <FaScroll className={styles.navIcon} />
          <span className={styles.navText}>Your Feed</span>
        </Link> */}

        <Link to="/ExploreCategories" className={styles.navItem}>
          <FaLayerGroup className={styles.navIcon} />
          <span className={styles.navText}>Categories</span>
        </Link>

        <Link to="/ExploreUsers" className={styles.navItem}>
          <FaUsers className={styles.navIcon} />
          <span className={styles.navText}>Users</span>
        </Link>

        <Link to="/Messages" className={styles.navItem}>
          <FaEnvelope className={styles.navIcon} />
          <span className={styles.navText}>Messages {messageCount > 0 ? `[${messageCount}]` : ""}</span>
        </Link>

        <Link to="/Notifications" className={styles.navItem}>
          <FaBell className={styles.navIcon} />
          <span className={styles.navText}>Notifications {notificationCount > 0 ? `[${notificationCount}]` : ""}</span>
        </Link>

        {/* <Link to="/About" className={styles.navItem}>
          <FaCog className={styles.navIcon} />
          <span className={styles.navText}>Settings</span>
        </Link> */}

        <Link to="/About" className={styles.navItem}>
          <FaInfoCircle className={styles.navIcon} />
          <span className={styles.navText}>About Rated</span>
        </Link>

        {isMobile && (
          <Link to="/ExploreUsers" className={styles.navItem}>
            <FaSearch className={styles.navIcon} />
            <span className={styles.navText}>Search</span>
          </Link>
        )}
      </div>

      {/* <div className={styles.buttonContainer}>
        <a href="https://play.google.com/store/apps/details?id=com.rated_native2&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            className={styles.badgeImage}
          />
        </a>
        <a href="https://apps.apple.com/us/app/rated/id6476522766">
          <img
            src="https://s3.eu-west-2.amazonaws.com/rated.images/apple_badge.png"
            alt="Apple Store"
            className={styles.badgeImageApple}
          />
        </a>
      </div> */}

      <div className={styles.footer}>
        <div className={styles.socialLinks}>
          {/* <a href="https://www.instagram.com/kansaschickenandpizza/?hl=en/">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/600px-Instagram_icon.png"
              alt="Instagram"
              className={styles.socialIcon}
            />
          </a> */}

          <a href="https://discord.gg/ZgWYJXNKKe">
            <img
              src="https://s3.eu-west-2.amazonaws.com/rated.images/discord_logo.png"
              alt="Discord"
              className={styles.socialIcon}
            />
          </a>
          <a href="https://twitter.com/RatedApp_">
            <img
              src="https://s3.eu-west-2.amazonaws.com/rated.images/X_logo.png"
              alt="Twitter"
              className={styles.socialIcon}
            />
          </a>

          <a href="https://www.linkedin.com/in/raza-sekha-118720109/">
            <img
              src="https://s3.eu-west-2.amazonaws.com/rated.images/linkedin_logo.png"
              alt="LinkedIn"
              className={styles.socialIcon}
            />
          </a>

          {/* <a href="https://discord.gg/6pu9qDkD">
            <img
              src="https://s3.eu-west-2.amazonaws.com/rated.images/facebook_logo.png"
              alt="Facebook"
              className={styles.socialIcon}
            />
          </a> */}
          <a href="https://www.youtube.com/channel/UCHVwvvfsmzAFLHUMmD-TiKA">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/e/ef/Youtube_logo.png"
              alt="Youtube"
              className={styles.socialIcon}
            />
          </a>
          <a href="https://www.tiktok.com/@rated.app">
            <img
              src="https://s3.eu-west-2.amazonaws.com/rated.images/tiktok_logo2.png"
              alt="TikTok"
              className={styles.socialIcon}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default LeftBar;
