import React, { useState, useEffect, useMemo, useContext, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import RatingDropdown from "../components/RatingDropdown";
import { UserContext } from "../components/UserContext";
import styles from "./MyProfile.module.css";
import ReactSlider from "react-slider";
import ReviewModal from "../components/ReviewModal";
import { formatDistanceToNow, set } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReportModal from "../components/ReportModal";
import CategoryDropdown from "../components/CategoryDropdown";
import {
  faArrowLeft,
  faCog,
  faEllipsisVertical,
  faUserCheck,
  faTag,
  faEnvelope,
  faEllipsisH,
  faFilter,
  faSearch,
  faCaretRight,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import RatingsGallery from "../components/RatingsGallery";
import SimpleDropdown from "../components/SimpleDropdown";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Alert } from "bootstrap";

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  Title,
  Tooltip,
  Legend
);

function UserPage() {
  const {
    userId,
    setUserId,
    username,
    setUsername,
    photoUrl,
    setPhotoUrl,
    followingCount,
    setFollowingCount,
  } = useContext(UserContext);
  const { profile_id, rating_id } = useParams();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [userWantlist, setUserWantlist] = useState([]);
  const [relationships, setRelationships] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [friendCount, setFriendCount] = useState(0);
  const [followingCount2, setFollowingCount2] = useState(0);
  const [followerCount, setFollowerCount] = useState(0);
  const [activeTab, setActiveTab] = useState("Profile");
  const [expandedSections, setExpandedSections] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [sortType, setSortType] = useState("Date");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [productShow, setProductShow] = useState(200);
  const [ratingRange, setRatingRange] = useState([1, 10]);
  const [ratingRangeDisplay, setRatingRangeDisplay] = useState([1, 10]);
  const [foundRatings, setFoundRatings] = useState(0);
  const [showOnlyReviews, setShowOnlyReviews] = useState(false);
  const [averageRating, setAverageRating] = useState(0);
  const [totalRatings, setTotalRatings] = useState(0);
  const [showTotalRatings, setShowTotalRatings] = useState(true);
  const [showAverageRating, setShowAverageRating] = useState(true);
  const [ratingDistribution, setRatingDistribution] = useState([]);
  const [weeklyRatings, setWeeklyRatings] = useState([]);
  const [weeklyAverage, setWeeklyAverage] = useState([]);
  const [selectedGraphCategory, setSelectedGraphCategory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [allRelations, setAllRelations] = useState([]);
  const [groupedRelations, setGroupedRelations] = useState({});
  const [friendRequests, setFriendRequests] = useState([]);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [userSocialMedia, setUserSocialMedia] = useState([]);
  const [dob, setDob] = useState([]);
  const [totalWishlist, setTotalWishlist] = useState(0);
  const [blocked, setBlocked] = useState([]);
  const [categoryHistograms, setCategoryHistograms] = useState([]);
  const [overallHistogram, setOverallHistogram] = useState([]);
  const [categoryAverage, setCategoryAverage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const [profilePrivacy, setProfilePrivacy] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isFriends, setIsFriends] = useState(false);
  const [isFollower, setIsFollower] = useState(false);
  const [assignedTags, setAssignedTags] = useState([]);
  const [inputTag, setInputTag] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [profileName, setProfileName] = useState("");
  const [filteredTags, setFilteredTags] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [reportReason, setReportReason] = useState("");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [showTag, setShowTag] = useState(false);

  const [wishlist, setWishlist] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showWishlist, setShowWishlist] = useState(false);
  const [activeRating, setActiveRating] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);

  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [displayFilter, setDisplayFilter] = useState(false);
  const [emptyFilter, setEmptyFilter] = useState(true);
  const [sliderValues, setSliderValues] = useState([1, 10]);

  const [showBlocked, setShowBlocked] = useState(false);
  const [showBlocked2, setShowBlocked2] = useState(false);
  const [hasReview, setHasReview] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updateFilters, setUpdateFilters] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);
  const [ratingsRerender, setRatingsRerender] = useState(false);
  const [sortOptions, setSortOptions] = useState(["Date", "Rating", "Clicks"]);

  const [unratedOnly, setUnratedOnly] = useState(false);
  const [ratedOnly, setRatedOnly] = useState(false);
  const [reviewOnly, setReviewOnly] = useState(false);

  const [myAccount, setMyAccount] = useState(false);

  // Function to toggle boolean state
  const handleBlockChange = () => {
    setShowBlocked(!showBlocked);
    setReviewOnly(false);
    setRatedOnly(false);
    setUnratedOnly(false);
  };

  const handleSelect = (selectedItem) => {
    setSortType(selectedItem);
  };

  const scrollViewRef = useRef(null);
  const scrollToTop = () => {
    window.scrollTo(0, 1300);
  };
  const [showFilter, setShowFilter] = useState(false);

  const handleRatedChange = (event) => {
    setRatedOnly(event.target.checked);
  };

  const handleUnratedChange = (event) => {
    setUnratedOnly(event.target.checked);
  };

  const handleReviewedChange = (event) => {
    setReviewOnly(event.target.checked);
  };

  const [hasMore, setHasMore] = useState(true);
  const unknownImg =
    "https://s3.eu-west-2.amazonaws.com/rated.images/unknown.png";

  useEffect(() => {
    if (profile_id === -1) {
      return;
    }
    console.log("Fetching ratings");
    setLoading(true);
    setRatings([]);

    fetch(`${apiUrl}/get_user_ratings?user_id=${profile_id}&self_id=${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setRatings(data.ratings); // Correct slicing here before setting state
        setLoading(false); // Move setLoading(false) here inside the async operation
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false); // Also handle setting loading false in case of error
      });
  }, [userId, profile_id]); // Ensure dependencies are correct

  // Effect to update states when location.state changes

  useEffect(() => {
    if (userId === profile_id) {
      //navigate("/MyProfile");
      return;
    } else if (profile_id < 500) {
      navigate("/ExternalProfile/" + profile_id);
    }
  }, [userId, profile_id, navigate]); // dependencies ensure this effect runs when any of the variables change

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const copyToClipboard = () => {
    const profileUrl = `https://www.rated.fun/profiles/${profile_id}`;
    navigator.clipboard
      .writeText(profileUrl)
      .then(() => {
        setShowCopyMessage(true);
        setTimeout(() => setShowCopyMessage(false), 2000); // Message disappears after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleModalSubmit = (productId, userId, reviewText) => {
    const payload = {
      product_id: productId,
      user_id: userId,
      review: reviewText,
    };

    fetch(`${process.env.REACT_APP_BACKEND_URL}/submit_review`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        // Handle success case
        // Find the updated product and change its user_rating.
        const updatedRatings = ratings.map((rating) => {
          if (rating.product_id === productId) {
            return {
              ...rating,
              review: reviewText, // Update the user_rating.
            };
          }
          return rating; // Leave all other products unchanged.
        });

        // Update the state with the modified products array.
        setRatings(updatedRatings);
      } else {
        // Handle error case
      }
    });
  };

  useEffect(() => {
    // Check if the window width is less than 768px on mount
    if (window.innerWidth < 1000) {
      navigate("/WelcomeM"); // Assuming '/mobile' is the route for LandingMobile
    }
  }, [navigate]);

  const categoryInfoMap = categories.reduce((acc, info) => {
    acc[info.category_id] = info;
    return acc;
  }, {});

  const handleFollow = () => {
    if (isFollowing) {
      return;
    }
    setFollowingCount(followingCount + 1);
    setIsFollowing(true);
    const payload = {
      user_id: userId,
      profile_id: profile_id,
      relationship: "Following",
    };

    fetch(`${apiUrl}/add_usertag`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
  };

  const handleUnfollow = () => {
    setIsFollowing(false);
    setFollowingCount(followingCount - 1);
    const payload = {
      user1Id: userId,
      user2Id: profile_id,
      relationship: "Following",
    };
    fetch(`${apiUrl}/remove_group`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
  };

  useEffect(() => {
    async function fetchProfile() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_user_profile?&user_id=${userId}&profile_id=${profile_id}`
        );

        const data = await response.json();
        setRelationships(data.relationships);
        setFollowers(data.followers);
        setBlocked(data.blocked_users);
        setCategories(data.categories);
        setFriendCount(data.friend_count);
        setFollowingCount2(data.following_count);
        setFollowerCount(data.follower_count);
        setFriendRequests(data.friend_requests);
        setUserSocialMedia(data.social_media);
        setUserInfo(data.info);
        setDob(data.info[0].dob);
        setProfilePhoto(data.info[0].photo_url);
        setProfileName(data.info[0].username);
        setTotalWishlist(data.total_wishlist);
        setAverageRating(data.average_rating);
        setTotalRatings(data.total_ratings);
        setCategoryHistograms(data.category_histograms);
        const overallHistogram = data.overall_histogram;
        setOverallHistogram(overallHistogram);
        if (
          (data.info[0].privacy === null ||
            data.info[0].privacy.toLowerCase() !== "public") &&
          data.is_friends === false
        ) {
          setProfilePrivacy(false);
        } else {
          setProfilePrivacy(true);
        }
        setIsFriends(data.is_friends);
        setIsFollowing(data.is_following);
        setIsFollower(data.is_following_you);
        setCoverPhoto(
          data.info[0].back_photo_user || data.info[0].back_photo || null
        );

        // Prepare distribution array based on overall histogram data
        const distribution = Array(10).fill(0);
        Object.entries(overallHistogram).forEach(([rating, count]) => {
          const index = parseInt(rating, 10);
          if (index >= 1 && index <= 10) {
            distribution[index - 1] = count;
          }
        });
        setRatingDistribution(distribution);

        if (profile_id === userId) {
          setMyAccount(true);
        } else {
          setMyAccount(false);
        }

        if (rating_id) {
          console.log("Rating ID: ", rating_id);
          navigate("/YourRatings", {
            state: {
              profile_id: profile_id, // Example state value
              profileName: data.info[0].username, // Example state value
              categories: data.categories, // Your categories array
              showWishlist: false,
              ratings: true,
              profilePhoto: data.info[0].photo_url,
              activeRating: rating_id,
            },
          });
        }
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    }
    console.log("Test");
    fetchProfile();
  }, [profile_id, rating_id, userId]); // Empty dependency array, only run once on mount.

  const processedRatings = useMemo(() => {
    if (ratings.length === 0) {
      return [];
    }
    let filteredRatings = [...ratings];

    console.log(activeRating, "Active Rating");

    const prioritizeRatingId =
      activeRating && Number(activeRating) > 0 ? Number(activeRating) : null;

    let priorityRating = null;

    if (prioritizeRatingId !== null) {
      const priorityIndex = filteredRatings.findIndex(
        (rating) => Number(rating.rating_id) === prioritizeRatingId
      );

      if (priorityIndex !== -1) {
        // Remove the priority rating from the array and store it
        [priorityRating] = filteredRatings.splice(priorityIndex, 1);
      }
    }

    // Filter by category
    if (selectedCategory !== null) {
      filteredRatings = filteredRatings.filter(
        (rating) => selectedCategory == rating.category_id
      );
    }

    // Sort based on sortType and sortDirection
    filteredRatings.sort((a, b) => {
      let compareVal = 0;
      switch (sortType) {
        case "Rating":
          compareVal = a.rating - b.rating;
          break;
        case "Date":
          compareVal = new Date(a.timestamp) - new Date(b.timestamp);
          break;
        case "Clicks":
          compareVal = a.interactions - b.interactions;
          break;
        case "Delta":
          // Calculate deltas
          const deltaA = Math.abs(a.rating - a.user_rating);
          const deltaB = Math.abs(b.rating - b.user_rating);

          // Check if both ratings are positive
          const positiveA = a.rating > 0 && a.user_rating > 0;
          const positiveB = b.rating > 0 && b.user_rating > 0;

          if (positiveA && !positiveB) {
            compareVal = -1; // a should come before b
          } else if (!positiveA && positiveB) {
            compareVal = 1; // b should come before a
          } else if (!positiveA && !positiveB) {
            compareVal = 0; // Both are non-positive, maintain their relative order
          } else {
            // Both are positive, sort by absolute delta
            compareVal = deltaA - deltaB;
          }
          break;
        default:
          break;
      }
      return sortDirection === "ASC" ? compareVal : -compareVal;
    });

    // Move items with negative ratings to the end
    filteredRatings.sort((a, b) => {
      const negativeA = a.rating <= 0 || a.user_rating <= 0;
      const negativeB = b.rating <= 0 || b.user_rating <= 0;

      if (negativeA && !negativeB) {
        return 1; // a should come after b
      } else if (!negativeA && negativeB) {
        return -1; // a should come before b
      } else {
        return 0; // Maintain relative order among negatives or positives
      }
    });

    if (reviewOnly) {
      filteredRatings = filteredRatings.filter(
        (rating) => rating.review && rating.review.trim() !== ""
      );
    }

    if (ratedOnly) {
      filteredRatings = filteredRatings.filter(
        (rating) => rating.user_rating > 0
      );
    } else if (unratedOnly) {
      filteredRatings = filteredRatings.filter(
        (rating) => rating.user_rating == -1 || rating.user_rating == null
      );
    }

    if (showBlocked) {
      filteredRatings = filteredRatings.filter((rating) => rating.rating <= -2);
    } else if (showWishlist) {
      filteredRatings = filteredRatings.filter((rating) => rating.rating == -1);
    } else {
      filteredRatings = filteredRatings.filter(
        (rating) =>
          rating.rating >= ratingRange[0] && rating.rating <= ratingRange[1]
      );
    }

    if (searchInput != "") {
      filteredRatings = filteredRatings.filter((rating) =>
        rating.name.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    // Prepend the prioritized rating if it exists
    if (priorityRating !== null) {
      filteredRatings.unshift(priorityRating);
      if (activeRating) {
        setActiveRating(null);
      }
    }

    setFoundRatings(filteredRatings.length);
    if (ratingsRerender) {
      setUpdateCount(updateCount + 1);
    } else {
      setRatingsRerender(true);
    }

    // Restrict the number of products (e.g., to show only the first 20)
    //return filteredRatings.slice(0, 200);
    //return filteredRatings.slice(0, productShow);
    setHasMore(filteredRatings.length > productShow);
    return filteredRatings;
  }, [
    updateFilters,
    productShow,
    sortType,
    sortDirection,
    searchInput,
    ratings,
    selectedCategory,
    showWishlist,
    wishlist,
    ratedOnly,
    unratedOnly,
    reviewOnly,
    ratingRange,
    showBlocked,
  ]); //

  const loadMoreRows = () => {
    setProductShow(productShow + 20);
    if (productShow >= foundRatings) {
      setHasMore(false);
    }
    console.log("Loading more rows");
  };

  const toggleWishlist = () => {
    setWishlist(!wishlist);
    setShowWishlist(!wishlist);
    setUpdateFilters(updateFilters + 1);
  };

  const handleRatingChange = (productId, userId, newRating, categoryId) => {
    setRatingsRerender(false);
    if (!myAccount) {
      const updatedRatings = ratings.map((rating) => {
        if (rating.product_id === productId) {
          return {
            ...rating,
            user_rating: newRating,
          };
        }
        return rating; // Leave all other products unchanged.
      });
      setRatings(updatedRatings);
    } else {
      const updatedRatings = ratings.map((rating) => {
        if (rating.product_id === productId) {
          return {
            ...rating,
            rating: newRating, // Update the user_rating.
            user_rating: newRating,
          };
        }
        return rating; // Leave all other products unchanged.
      });
      setRatings(updatedRatings);
    }

    // Update the state with the modified products array.
  };

  const handleLike = (userId, item) => {
    if (!userId) {
      return;
    }
    setRatingsRerender(false);
    const ratingId = item.rating_id;
    const like = item.user_liked ? true : false;
    const payload = {
      user_id: userId,
      rating_id: ratingId,
      like: !like,
    };

    fetch(`${apiUrl}/like_rating`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    // Update the state with the modified products array.
    const updatedRatings = ratings.map((rating) => {
      if (rating.rating_id === ratingId) {
        return {
          ...rating,
          user_liked: !like,
          likes: !like ? rating.likes + 1 : rating.likes - 1,
        };
      }
      return rating;
    });

    setRatings(updatedRatings);
  };

  useEffect(() => {
    if (!relationships.length & !followers.length) return;
    const newAllRelations = [
      ...relationships,
      ...followers.map((follower) => ({
        ...follower,
        relationship: "Followers",
      })),
    ];

    setAllRelations(newAllRelations);

    const newGroupedRelations = newAllRelations.reduce((acc, relation) => {
      if (!acc[relation.relationship]) {
        acc[relation.relationship] = [];
      }
      acc[relation.relationship].push(relation);
      return acc;
    }, {});

    // Sort users within each relationship type by relation.similarity
    for (let relationshipType in newGroupedRelations) {
      newGroupedRelations[relationshipType].sort(
        (a, b) => b.similarity - a.similarity
      );
    }

    console.log(newGroupedRelations);
    setGroupedRelations(newGroupedRelations);
    console.log("Groups Updated");
  }, [relationships, followers]);

  const graphRatings = useMemo(() => {
    // Start with a fresh distribution array
    let distribution = Array(10).fill(0);
    setCategoryAverage(null);

    if (selectedCategory === "All" || selectedCategory === null) {
      // Calculate distribution for 'All' categories
      Object.entries(overallHistogram).forEach(([rating, count]) => {
        const index = parseInt(rating, 10) - 1;
        if (index >= 0 && index < 10) {
          distribution[index] = count;
        }
      });
      // Optionally calculate an average if needed
    } else {
      // Calculate distribution for selected category
      const categoryData = categoryHistograms.find(
        (cat) => cat.category_id === parseInt(selectedCategory, 10)
      );
      if (categoryData) {
        Object.entries(categoryData.ratings).forEach(([rating, count]) => {
          const index = parseInt(rating, 10) - 1; // Convert the rating key to an index
          if (index >= 0 && index < 10) {
            distribution[index] = count;
          }
        });
        //Look up category average from categories
        const categoryInfo = categoryInfoMap[selectedCategory];
        if (categoryInfo) {
          setCategoryAverage(categoryInfo.avg_rating);
        }
      }
    }

    // Set the distribution state
    setRatingDistribution(distribution);

    // Placeholder return, adjust as needed
    return [];
  }, [selectedCategory, categoryHistograms, overallHistogram]);

  // Event handler to remove the user and make a backend call.
  const removeTag = (remove_tag, userId, profile_id) => {
    // 1. Remove the user locally from the groupedRelations.
    console.log("Remove Tag");
    const updatedTags = assignedTags.filter((tag) => tag !== remove_tag);
    setAssignedTags(updatedTags);

    if (remove_tag === "Friends") {
      setFriendCount(friendCount - 1);
    }
    if (remove_tag === "Following") {
      setFollowingCount2(followingCount2 - 1);
    }

    // 2. Make a POST request to your backend with the necessary information.
    const payload = {
      relationship: remove_tag,
      user1Id: userId,
      user2Id: profile_id,
    };

    fetch(`${apiUrl}/remove_group`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        // Handle success case
        console.log("User removed successfully from the backend.");
      } else {
        // Handle error case
        console.error("Failed to remove user from the backend.");
      }
    });
  };

  const isValidTag = (tag) => {
    const regex = /^[a-zA-Z0-9-_]+$/;
    return regex.test(tag);
  };

  const formatTag = (tag) => {
    return tag.charAt(0).toUpperCase() + tag.slice(1).toLowerCase();
  };

  const handleAssignGroup = () => {
    if (!isValidTag(inputTag)) {
      setErrorMessage(
        "Invalid input. Only letters, numbers, underscores, and dashes are allowed. No spaces permitted."
      );
      return;
    }

    const formattedTag = formatTag(inputTag);

    // Check for duplicates
    if (assignedTags.includes(formattedTag)) {
      setErrorMessage(
        "This tag is already assigned. Please use a different tag."
      );
      return;
    }

    setAssignedTags([...assignedTags, formattedTag]);

    const payload = {
      user_id: userId,
      profile_id: profile_id,
      relationship: formattedTag,
    };

    fetch(`${apiUrl}/add_usertag`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    setInputTag("");
    setErrorMessage(""); // Clear the error message
  };

  const handleReportUser = () => {
    setDialogVisible(true);
  };

  const handleReportCancel = () => {
    setDialogVisible(false);
  };

  const handleBlockUser = () => {
    const payload = {
      user_id: userId,
      profile_id: profile_id,
    };

    fetch(`${apiUrl}/block_user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    window.alert("User has been blocked.");
  };

  const settingsModal = () => {
    return (
      <div className={styles.modal} onClick={handleModalClose}>
        <div className={styles.modalContent}>
          <div className={styles.modalBody}>
            <div className={styles.optionText}>
              <h3 className={styles.optionText} onClick={copyToClipboard}>
                Share Profile
              </h3>
            </div>
            {isFollower && isFollowing && (
              <Link to={`/DirectMessage/${profile_id}`}>
                <div className={styles.optionText}>
                  <h3 className={styles.optionText}>Direct Message</h3>
                </div>
              </Link>
            )}
            {isFollowing && (
              <div className={styles.optionText}>
                <h3 className={styles.optionText} onClick={handleUnfollow}>
                  Unfollow
                </h3>
              </div>
            )}
            {userId && (
              <div className={styles.optionText}>
                <h3 className={styles.optionText} onClick={handleBlockUser}>
                  Block User
                </h3>
              </div>
            )}
            {userId && (
              <div className={styles.optionText}>
                <h3 className={styles.optionText} onClick={handleReportUser}>
                  Report User
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const filterTags = (input) => {
    setInputTag(input);
    setFilteredTags(
      allTags.filter((tag) => tag.toLowerCase().includes(input.toLowerCase()))
    );
    setShowDropdown(true);
  };

  const handleTagClick = (tag) => {
    setInputTag(tag);
    setShowDropdown(false);
  };

  const formatDateJoined = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${year}`;
  };

  if (!userInfo.length) {
    return (
      <div className={styles.container}>
        <h1 className={styles.page_title}>@{profileName}'s Profile</h1>
      </div>
    ); // Or any other loading state you'd like to display
  }

  return (
    <div className={styles.container}>
      {/* <h1 className={styles.page_title}>@{profileName}'s Profile</h1>

      <div className={styles.backIconContainer} onClick={handleBackClick}>
        <FontAwesomeIcon icon={faArrowLeft} className={styles.backArrow} />
      </div> */}

      {/* 3. Content Rendering */}
      {activeTab === "Profile" && (
        <div>
          <div className={styles.profileContainer}>
            {/* <div className={styles.flags} title={userInfo[0].country}>
              {userInfo[0].country_url ? (
                <img
                  src={userInfo[0].country_url}
                  title={userInfo[0].country}
                />
              ) : null}
              {userInfo[0].country2_url ? (
                <img
                  src={userInfo[0].country2_url}
                  title={userInfo[0].country2}
                />
              ) : null}
            </div> */}

            {coverPhoto ? (
              <img
                src={coverPhoto}
                alt="Cover Photo"
                className={styles.coverPhoto}
              />
            ) : (
              <div className={styles.coverPhotoEmpty}></div>
            )}
            {/* <div
              className={styles.settingsIconContainer}
              onClick={() => setShowModal(!showModal)}
            >
              <FontAwesomeIcon
                icon={faEllipsisVertical}
                className={styles.settingsIcon}
              />
            </div> */}
            {showModal && settingsModal()}
            {dialogVisible && (
              <ReportModal
                isOpen={dialogVisible}
                onClose={handleReportCancel}
                onSubmit={handleReportCancel}
                product_id={null}
                profile_id={profile_id}
              />
            )}
            {showCopyMessage && (
              <div className={styles.copy_message}>
                Link Copied to Clipboard!
              </div>
            )}

            <img
              src={userInfo[0].photo_url}
              alt="User Profile"
              className={styles.profilePic}
            />
            <h2 className={styles.username}> {userInfo[0].username} </h2>
            {userInfo[0].similarity > 0 ? (
              <h4 className={styles.userType}>
                {" "}
                {userInfo[0].similarity}% Similarity{" "}
              </h4>
            ) : (
              <h4 className={styles.userType2}> No Similarity</h4>
            )}

            {!myAccount ? (
              <div className={styles.user_groups}>
                <div className={styles.is_relations}>
                  {isFollowing && isFollower ? (
                    <h3 className={styles.followButton}>
                      {" "}
                      <FontAwesomeIcon
                        icon={faUserCheck}
                        className={styles.friendIcon}
                      />
                      Friends
                    </h3>
                  ) : isFollowing ? (
                    <h3 className={styles.followButton}>Following</h3>
                  ) : (
                    <h3
                      onClick={() => handleFollow()}
                      className={styles.followButton}
                    >
                      Follow
                    </h3>
                  )}
                </div>
                <div className={styles.icons}>
                  <FontAwesomeIcon
                    icon={faTag}
                    className={styles.tagIcon}
                    onClick={() => setShowTag(!showTag)}
                  />
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className={styles.tagIcon}
                    onClick={() =>
                      isFriends
                        ? navigate(`/DirectMessage/${profile_id}`)
                        : window.alert(
                            "You must be friends to message this user."
                          )
                    }
                  />
                  <FontAwesomeIcon
                    icon={faEllipsisH}
                    className={styles.tagIcon}
                    onClick={() => setShowModal(!showModal)}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.user_groups}>
                <div className={styles.is_relations}>
                  <Link to="/EditProfile">
                    <h3 className={styles.followButton}>
                      {" "}
                      <FontAwesomeIcon
                        icon={faPencil}
                        className={styles.friendIcon}
                      />
                      Edit Profile
                    </h3>
                  </Link>
                </div>
                <div className={styles.icons}>
                  <p className={styles.joinedText}>
                    Joined {formatDateJoined(userInfo[0].date_added)}
                  </p>
                </div>
              </div>
            )}

            {showTag && (
              <div className={styles.usertags}>
                <div className={styles.tagInput}>
                  <div className={styles.input_dropdown}>
                    <input
                      className={styles.tagInputSearch}
                      value={inputTag}
                      onChange={(e) => filterTags(e.target.value)}
                      onFocus={() => setShowDropdown(true)}
                      onBlur={() =>
                        setTimeout(() => setShowDropdown(false), 100)
                      } // Delay to allow click event
                      placeholder="Tag User"
                    />
                    {showDropdown && inputTag && (
                      <div className={styles.customDropdown}>
                        {filteredTags.map((tag) => (
                          <div
                            className={styles.dropdownItem}
                            key={tag}
                            onMouseDown={() => handleTagClick(tag)}
                          >
                            {tag}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <p className={styles.bioText}>{userInfo[0].bio}</p>
            {/* <Link to="/">
                <p className={styles.logout} onClick={handleLogout}>
                  [Logout]
                </p>
              </Link> */}
            {/* <h3>
                {userInfo[0].first_name} {userInfo[0].last_name}
              </h3> */}

            <div className={styles.ratingCounts}>
              <div
                className={styles.ratingStat}
                onClick={() => {
                  scrollToTop();
                  setShowWishlist(false);
                  setSortOptions(["Date", "Rating", "Clicks"]);
                }}
              >
                <h4 className={styles.ratingText}>{totalRatings} </h4>
                <h4 className={styles.ratingLabel}>Ratings</h4>
              </div>

              <div
                className={styles.ratingStat}
                onClick={() => {
                  scrollToTop();
                  setShowWishlist(true);
                  setSortOptions(["Date"]);
                }}
              >
                <h4 className={styles.ratingText}>{totalWishlist} </h4>
                <h4 className={styles.ratingLabel}>Wishlist</h4>
              </div>

              <Link
                to={"/YourNetwork"}
                state={{
                  groupedRelations: groupedRelations, // Ensure this is not undefined
                  profile_id: profile_id, // Ensure userId is defined
                  profileName: profileName, // Should pass 'Test'
                  self: false,
                }}
                className={styles.ratingStat}
              >
                <h4 className={styles.ratingText}>{followerCount} </h4>
                <h4 className={styles.ratingLabel}>Followers</h4>
              </Link>

              <Link
                to={"/YourNetwork"}
                state={{
                  groupedRelations: groupedRelations, // Ensure this is not undefined
                  profile_id: profile_id, // Ensure userId is defined
                  profileName: profileName, // Should pass 'Test'
                  self: false,
                }}
                className={styles.ratingStat}
              >
                <h4 className={styles.ratingText}>{followingCount2} </h4>
                <h4 className={styles.ratingLabel}>Following</h4>
              </Link>
            </div>
          </div>

          {inputTag && inputTag !== "" && (
            <button className={styles.addTagButton} onClick={handleAssignGroup}>
              Add Tag
            </button>
          )}
          <div className={styles.input_error_message}>{errorMessage}</div>

          {assignedTags.length > 0 && (
            <ul className={styles.tagList}>
              {assignedTags
                .filter((tag) => tag !== "Following" && tag !== "Friends")
                .map((tag) => (
                  <li key={tag} className={styles.tagBubble}>
                    #{tag}
                    <span
                      className={styles.tagClose}
                      onClick={() => removeTag(tag, userId, profile_id)}
                    >
                      X
                    </span>
                  </li>
                ))}
            </ul>
          )}

          <div className={styles.category_container}>
            <div className={styles.categoryInfo}>
              {categories.map((category) => (
                <div
                  style={{ height: myAccount ? 150 : 220 }}
                  className={`${styles.categoryContainer} ${
                    selectedCategory === category.category_id
                      ? styles.selectedCategory
                      : ""
                  }`}
                >
                  <div
                    className={styles.category_info_container}
                    onClick={() => {
                      setSelectedCategory((prevCategory) =>
                        prevCategory === category.category_id
                          ? null
                          : category.category_id
                      );
                    }}
                  >
                    <h3 className={styles.categoryName}>{category.name}</h3>
                    {category.similarity > 0 && (
                      <h4 className={styles.categoryCount}>
                        {category.similarity}% Similarity
                      </h4>
                    )}
                    <img
                      className={styles.category_icon}
                      src={category.icon_url}
                      alt={category.name}
                      title={category.name}
                    />
                    <h4 className={styles.categoryCount}>
                      {category.rating_count} Ratings
                    </h4>
                    {category.common > 0 && (
                      <h4 className={styles.categoryCount}>
                        {category.common} Mutual
                      </h4>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.charts}>
            <div className={styles.chartTitles}>
              {!categoryAverage ? (
                <h2>
                  {categories.length} Categories Rated - Average Rating{" "}
                  {averageRating}
                </h2>
              ) : (
                <h2>Average Rating: {categoryAverage || averageRating}</h2>
              )}
            </div>
            <Bar
              className={styles.bar_chart}
              data={{
                labels: Array.from({ length: 10 }, (_, i) => i + 1),
                datasets: [
                  {
                    label: "Number of Ratings",
                    data: ratingDistribution,
                    backgroundColor: "rgba(86, 0, 107, 1)",
                    borderColor: "rgba(100, 100, 100, 1)",
                    borderWidth: 1,
                    barPercentage: 1.2,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    displayColors: false,
                    callbacks: {
                      label: function (context) {
                        return context.parsed.y; // Only return the y-value of the data point (i.e., the frequency)
                      },
                      title: function () {
                        return ""; // Return an empty string to suppress the title in the tooltip
                      },
                    },
                  },
                },
              }}
            />
          </div>

          <div
            className={styles.filterContainer}
            onClick={() => {
              scrollToTop();
              setShowFilter(!showFilter);
            }}
          >
            <FontAwesomeIcon icon={faFilter} className={styles.filterButton} />
          </div>

          <div className={styles.dropdownFilters}>
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Search Ratings"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <CategoryDropdown
              categories={categories}
              selectedCategory={selectedCategory}
              onCategoryChange={setSelectedCategory}
              icon={true}
              name="Category"
            />
            <SimpleDropdown
              options={sortOptions}
              selectedOption={sortType}
              onSelect={handleSelect}
              name="Sort Type"
            />
            <button
              className={styles.sortButton}
              onClick={() =>
                setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC")
              }
            >
              {sortDirection === "ASC" ? "↑" : "↓"}
            </button>

            <div
              className={styles.switchContainer}
              onClick={() => {
                scrollToTop();
                setShowWishlist(!showWishlist);
                if (showWishlist) {
                  setSortOptions(["Date", "Rating", "Clicks"]);
                } else {
                  setSortOptions(["Date"]);
                }
              }}
            >
              {showWishlist ? "Ratings" : "Wishlist"}{" "}
              <FontAwesomeIcon
                icon={faCaretRight}
                className={styles.switchButton}
              />
            </div>
          </div>

          {showFilter && (
            <div className={styles.ratingFilters}>
              {!showWishlist && (
                <div className={styles.checkboxFilters}>
                  {profile_id && (
                    <div className={styles.checkboxFilters}>
                      <div className={styles.checkboxContainer}>
                        <input
                          type="checkbox"
                          checked={ratedOnly}
                          onChange={handleRatedChange}
                        />
                        Rated
                      </div>
                      <div className={styles.checkboxContainer}>
                        <input
                          type="checkbox"
                          checked={unratedOnly}
                          onChange={handleUnratedChange}
                        />
                        Unrated
                      </div>
                    </div>
                  )}
                  <div className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={reviewOnly}
                      onChange={handleReviewedChange}
                    />
                    Reviewed
                  </div>
                  <div className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={showBlocked}
                      onChange={handleBlockChange}
                    />
                    Skipped
                  </div>
                </div>
              )}

              {!showWishlist && !showBlocked && (
                <ReactSlider
                  className={styles.ratingSlider}
                  thumbClassName={styles.thumb}
                  trackClassName={styles.track}
                  defaultValue={[ratingRangeDisplay[0], ratingRangeDisplay[1]]}
                  min={1}
                  max={10}
                  step={1}
                  pearling
                  onAfterChange={(values) => {
                    setRatingRange(values);
                  }}
                  onChange={(values) => {
                    setRatingRangeDisplay(values);
                  }}
                />
              )}
              {!showWishlist && !showBlocked && (
                <div className={styles.ratingRangeLabel}>
                  Rating Range: {ratingRangeDisplay[0]} -{" "}
                  {ratingRangeDisplay[1]}
                </div>
              )}
            </div>
          )}

          <div className={styles.ratingGallery}>
            <RatingsGallery
              ratings={processedRatings.slice(0, productShow)}
              loading={loading}
              userId={userId}
              handleRatingChange={handleRatingChange}
              loadMoreRows={loadMoreRows}
              unknownImg={unknownImg}
              showTitle={false}
              handleLike={handleLike}
              hasMore={hasMore}
              profile_id={profile_id}
              setRatings={setRatings}
              profilePhoto={profilePhoto || null}
              profileName={profileName || null}
              screen={profile_id ? "UserProfile" : "YourRatings"}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default UserPage;
