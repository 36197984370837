import React, { useState, useEffect, useContext, useRef } from "react";
import "./ProductPage.css";
import { Link, useParams } from "react-router-dom";
import RatingDropdown from "../components/RatingDropdown";
import { UserContext } from "../components/UserContext";
import ReviewModal from "../components/ReviewModal";
import { useNavigate } from "react-router-dom";
import ProductImageUpload from "../components/ImageUploadMultiple";
import styles from "./ProductPage.module.css";
import { FaArrowLeft, FaArrowRight, FaTh, FaThumbsUp } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faEllipsisVertical,
  faImage,
  faThumbsUp,
  faChevronRight,
  faChevronLeft,
  faStar,
  faPlay,
  faPause,
  faArrowRight,
  faBan,
  faCircleCheck,
  faSquareArrowUpRight,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import GiveRating from "../components/GiveRating";
import { formatDistanceToNow } from "date-fns";
import SimpleDropdown from "../components/SimpleDropdown";
import ReportModal from "../components/ReportModal";
import InfiniteScroll from "react-infinite-scroll-component";

function ProductKeywords({ keywords, categoryId }) {
  const [votes, setVotes] = useState({});

  // Toggle vote function
  const toggleVote = (keyword, voteType) => {
    setVotes((prev) => {
      if (prev[keyword] === voteType) {
        const { [keyword]: _, ...rest } = prev;
        return rest;
      }
      return { ...prev, [keyword]: voteType };
    });
  };

  return (
    <div className="product-page-right-keywords">
      {keywords.length > 0
        ? keywords.map(
            (
              item,
              index // 'item' now represents each object
            ) => (
              <Link to={`/categories/${categoryId}/${item.keyword}`}>
                <div key={item.keyword} className="product-page-keyword">
                  #{item.keyword}
                </div>
              </Link>
            )
          )
        : null}
    </div>
  );
}

function ProductPage() {
  const { product_id } = useParams();
  const [product, setProduct] = useState(null);
  const [ratings, setRatings] = useState([]);
  const [raters, setRaters] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [relationship, setRelationship] = useState("Everyone");
  const [averageRating, setAverageRating] = useState(null);
  const [numberRatings, setNumberRatings] = useState(0);
  const [userRating, setUserRating] = useState("?");
  const [userRatingDate, setUserRatingDate] = useState("Rate Now");
  const [error, setError] = useState(null);
  const [sortDirection, setSortDirection] = useState("DESC");
  const [sortType, setSortType] = useState("Date");
  const [tags, setTags] = useState([]); // New line added here
  const [predictedRating, setPredictedRating] = useState(null);
  const [predictedRaters, setPredictedRaters] = useState(null); // New line added here
  const [showOnlyReviewers, setShowOnlyReviewers] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalRater, setModalRater] = useState(""); // New line added here
  const [currentKeyword, setCurrentKeyword] = useState("");
  const [userReview, setUserReview] = useState("");
  const [username, setUsername] = useState(""); // New line added here
  const [userPhoto, setUserPhoto] = useState(""); // New line added here
  const [raterShow, setRaterShow] = useState(100);
  const [activeTab, setActiveTab] = useState("Raters");
  const [categoryId, setCategoryId] = useState(-1); // New line added here
  const [imageUrl, setImageUrl] = useState(""); // New line added here
  const [userImage, setUserImage] = useState(""); // New line added here
  const [ratingImages, setRatingImages] = useState([]); // New line added here
  const [imageSelection, setImageSelection] = useState(0);
  const [childProducts, setChildProducts] = useState([]);
  const [childLoading, setChildLoading] = useState(false);
  const [activeChildTab, setActiveChildTab] = useState("Raters");
  const [ratingUpdate, setRatingUpdate] = useState(0);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const scrollViewRef = React.useRef(null);
  const sortOptions = ["Rating", "Date", "Similarity"];
  const [sortedRatings, setSortedRatings] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);

  const [modalUserId, setModalUserId] = useState(null);
  const [modalUsername, setModalUsername] = useState(null);
  const [modalPhoto, setModalPhoto] = useState(null);
  const [modalReview, setModalReview] = useState(null);
  const [modalRating, setModalRating] = useState(null);
  const [modalTimestamp, setModalTimestamp] = useState(null);
  const [showOnlyExternal, setShowOnlyExternal] = useState(false);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [timerId, setTimerId] = useState(null);
  const [flashMessage, setFlashMessage] = useState(null);
  const [selectedRating, setSelectedRating] = useState(0);
  const [pressable, setPressable] = useState(true);
  const [previousProduct, setPreviousProduct] = useState(null);
  const [previousRaters, setPreviousRaters] = useState([]);
  const [previousExternalRaters, setPreviousExternalRaters] = useState([]);
  const [previousPredictedRating, setPreviousPredictedRating] = useState(null);
  const [previousAverageRating, setPreviousAverageRating] = useState(null);
  const [nextProduct, setNextProduct] = useState(null);
  const [nextRaters, setNextRaters] = useState([]);
  const [nextExternalRaters, setNextExternalRaters] = useState([]);
  const [nextPredictedRating, setNextPredictedRating] = useState(null);
  const [nextAverageRating, setNextAverageRating] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(null);
  const [clipUrl, setClipUrl] = useState(null);
  const [review, setReview] = useState(null);
  const [externalRaters, setExternalRaters] = useState([]);

  const handleSelect = (option) => {
    setSortType(option);
  };

  const scrollToTop = () => {
    scrollViewRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  const handleLike = (userId, rating_id, user_liked, rater_id) => {
    if (!userId) {
      return;
    }

    if (userId === rater_id) {
      return;
    }

    const ratingId = rating_id;
    const like = user_liked ? true : false;
    const payload = {
      user_id: userId,
      rating_id: ratingId,
      like: !like,
    };

    console.log("Payload:", payload);

    fetch(`${apiUrl}/like_rating`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    // Update the state with the modified products array.
    const updatedRatings = ratings.map((rating) => {
      if (rating.rating_id === ratingId) {
        return {
          ...rating,
          user_liked: !like,
          likes: !like ? rating.likes + 1 : rating.likes - 1,
        };
      }
      return rating; // Leave all other products unchanged.
    });
    setRatings(updatedRatings);
  };

  const {
    userId,
    ratingCount,
    setRatingCount,
    productCount,
    setProductCount,
    productIds,
    setProductIds,
    setShowLoginPopup,
    feedCategories,
  } = useContext(UserContext);
  const [user_id, setUser_id] = useState(userId); // New line added here
  const unknownImg =
    "https://s3.eu-west-2.amazonaws.com/rated.images/unknown.png";

  const redirect = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const deepLink = `rated://products/${product_id}`;
    const appStoreLink = "https://apps.apple.com/us/app/rated/id6476522766"; // Adjust with your app's URL
    const playStoreLink =
      "https://play.google.com/store/apps/details?id=com.rated_native2"; // Adjust with your app's URL
    let fallbackLink;

    if (/android/i.test(userAgent)) {
      fallbackLink = playStoreLink;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      fallbackLink = appStoreLink;
    } else {
      // Non-mobile device detected; do nothing and exit the function
      return;
    }

    // Attempt to redirect to the deep link
    window.location.href = deepLink;

    // Fallback to the app store link if the app doesn't open
    setTimeout(() => {
      window.location.href = fallbackLink;
    }, 2500); // 2.5 seconds delay
  };

  redirect();

  const onFileUploaded = (url) => {
    const payload = {
      userId: userId,
      productId: product_id, // Assuming the product id is stored in product.id
      photoUrl: url,
    };
    fetch(`${process.env.REACT_APP_BACKEND_URL}/add_review_photo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        setUserImage(url);
        console.log(url);
        {
          imageUrl
            ? setUserImage(url)
            : setProduct({ ...product, product_photo: imageUrl });
        }
      } else {
        // Handle error case
      }
    });
  };

  const submitImage = (event) => {
    event.preventDefault(); // This will prevent the default form submit action

    const payload = {
      product_id: product_id, // Assuming the product id is stored in product.id
      photoUrl: imageUrl,
    };

    fetch(`${process.env.REACT_APP_BACKEND_URL}/submit_image`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        setProduct({ ...product, product_photo: imageUrl });
        setImageUrl("");
      } else {
        // Handle error case
      }
    });
  };

  const handleDeleteProduct = () => {
    // Confirmation dialog
    if (window.confirm("Are you sure you want to delete this product?")) {
      const payload = {
        product_id: product_id,
      };

      fetch(`${process.env.REACT_APP_BACKEND_URL}/delete_product`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }).then((response) => {
        if (response.ok) {
          alert("Product deleted successfully!");
          navigate("/"); // Navigate to the home page
        } else {
          // Handle error case
          alert("Error deleting product.");
        }
      });
    } else {
      // If the user clicks 'Cancel', nothing happens
      console.log("Product deletion cancelled.");
    }
  };

  const addKeywords = async () => {
    const newKeywords = currentKeyword
      .split(",")
      .map((kw) => kw.replace(/\s+/g, "").toLowerCase())
      .filter((kw) => kw)
      .map((kw) => ({ keyword: kw, upvotes: 0, downvotes: 0 })); // Transform each keyword to the expected object structure

    // Assuming the product_id is available in the state or via props
    const payload = {
      product_id: product_id,
      keywords: newKeywords.map((kwObj) => kwObj.keyword),
      category_id: product.category_id,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/add_keywords`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data.message); // Should log "Keywords added successfully!"
      } else {
        console.error("Failed to add keywords:", await response.text());
      }
    } catch (err) {
      console.error("Error when calling backend:", err);
    }

    setKeywords([...keywords, ...newKeywords]);
    setCurrentKeyword("");
  };

  const removeKeyword = (index) => {
    const newKeywords = [...keywords];
    newKeywords.splice(index, 1);
    setKeywords(newKeywords);
  };

  const handleRatingChange = (productId, newRating) => {
    setUserRating(newRating);
    setRatingUpdate((prev) => prev + 1);
  };

  const handleReviewClick = (modalRater) => {
    console.log(modalRater);
    if (modalRater === -1) {
      setModalRater(userId);
      setModalUserId(userId);
      setModalUsername(username);
      setModalPhoto(userPhoto);
      setModalReview(userReview);
      setModalRating(userRating);
      setModalTimestamp(userRatingDate);
      setIsModalOpen(true);
    } else {
      setModalRater(modalRater.user_id);
      setModalUserId(modalRater.user_id);
      setModalUsername(modalRater.username);
      setModalPhoto(modalRater.user_photo);
      setModalReview(modalRater.review);
      setModalRating(modalRater.rating);
      setModalTimestamp(modalRater.timestamp);
      setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setModalRater("");
    setIsModalOpen(false);
    setModalUserId(null);
    setModalUsername(null);
    setModalPhoto(null);
    setModalReview(null);
    setModalRating(null);
    setModalTimestamp(null);
  };

  const copyToClipboard = () => {
    const profileUrl = `https://www.rated.fun/products/${product_id}`;
    navigator.clipboard
      .writeText(profileUrl)
      .then(() => {
        setShowCopyMessage(true);
        setTimeout(() => setShowCopyMessage(false), 2000); // Message disappears after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleEditProduct = () => {
    navigate(`/EditProduct/${product_id}`);
  };

  const handleReportProduct = () => {
    setShowReportModal(true);
  };

  const closeReportModal = () => {
    setShowReportModal(false);
  };

  const handleSettingsClose = () => {
    setShowModal(false);
  };

  const settingsModal = () => {
    return (
      <div className={styles.modal} onClick={handleSettingsClose}>
        <div className={styles.modalContent}>
          <div className={styles.modalBody}>
            <div className={styles.optionText}>
              <h3 className={styles.optionText} onClick={copyToClipboard}>
                Share Product
              </h3>
            </div>
            {userId > 0 && (
              <div className={styles.optionText}>
                <h3 className={styles.optionText} onClick={handleEditProduct}>
                  Edit Product
                </h3>
              </div>
            )}
            {userId > 0 && (
              <div className={styles.optionText}>
                <h3 className={styles.optionText} onClick={handleReportProduct}>
                  Report Product
                </h3>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleModalSubmit = (productId, userId, reviewText) => {
    const payload = {
      product_id: productId,
      user_id: userId,
      review: reviewText,
    };
    setUserReview(reviewText);

    fetch(`${process.env.REACT_APP_BACKEND_URL}/submit_review`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        // Handle success case
        // Find the updated product and change its user_rating.
        const updatedRatings = ratings.map((rating) => {
          if (rating.product_id === productId) {
            return {
              ...rating,
              review: reviewText, // Update the user_rating.
            };
          }
          return rating; // Leave all other products unchanged.
        });

        // Update the state with the modified products array.
        setRatings(updatedRatings);
      } else {
        // Handle error case
      }
    });
  };

  // Adjust imageSelection
  const handleRightClick = () => {
    if (imageSelection < ratingImages.length) {
      setImageSelection(imageSelection + 1);
    }
  };

  const handleLeftClick = () => {
    if (imageSelection > 0) {
      setImageSelection(imageSelection - 1);
    }
  };

  const calculatePredictedRating = (raters) => {
    let numerator = 0;
    let denominator = 0;
    let count = 0;

    raters.forEach((rater) => {
      const similarityScore =
        rater.similarity_score !== undefined ? rater.similarity_score : 0;
      let weight = (similarityScore - 55) * 2; // use let instead of const
      if (weight < 0) weight = 0;
      if (weight > 0) count++;
      numerator += weight * rater.rating;
      denominator += weight;
    });

    setPredictedRaters(count);

    return denominator !== 0 ? numerator / denominator : 0;
  };

  useEffect(() => {
    async function fetchTags() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_new_tags?userId=${user_id}`
        );
        const data = await response.json();
        setTags(["Everyone", ...data]);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    }

    fetchTags();
  }, []); // Empty dependency array, only run once on mount.

  useEffect(() => {
    async function fetchProduct() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_product_info?product_id=${product_id}&relationship=${relationship}&sort_direction=${sortDirection}&sort_type=${sortType}&user_id=${user_id}`,
          {
            headers: {
              "Cache-Control": "no-store",
            },
          }
        );
        const responseData = await response.json();
        const productData = responseData.product_info[0];
        setProduct(productData);
        const keywords = responseData.keywords;
        setKeywords(keywords);
        const user_score = responseData.user_rating;
        setUserRating(
          user_score === null || user_score === "nan" ? "?" : user_score
        );
        const user_date = responseData.user_rating_date;
        setUserRatingDate(user_date);
        const user_review = responseData.user_review;
        setUserReview(user_review);
        const user_name = responseData.user_name;
        setUsername(user_name);
        const user_photo = responseData.user_photo;
        setUserPhoto(user_photo);
        const user_image = responseData.rating_photo;
        setUserImage(user_image);
        setRatingImages(responseData.images_list);
        console.log(responseData.images_list);
        setCoverPhoto(productData.cover_photo || null);

        // Check if predictedRating is not yet set (this will ensure calculation is done only once)
        if (predictedRating === null) {
          const computedRating = calculatePredictedRating(
            responseData.rating_info
          );
          setPredictedRating(computedRating);
        }
      } catch (error) {
        setError("Failed to fetch products.");
      }
    }

    fetchProduct();
  }, [product_id, relationship]);

  useEffect(() => {
    async function fetchProduct2() {
      if (childLoading) {
        return;
      }
      try {
        setChildLoading(true);
        const response = await fetch(
          `${
            process.env.REACT_APP_BACKEND_URL
          }/get_product_info_raters?product_id=${product_id}&relationship=${relationship}&sort_direction=${sortDirection}&sort_type=${"timestamp"}&user_id=${userId}&category_id=${
            product.category_id
          }`,
          {
            headers: {
              "Cache-Control": "no-store",
            },
          }
        );
        const responseData2 = await response.json();
        const sortedChildProducts = responseData2.child_products.sort(
          (a, b) => {
            let ratingA = a.average_rating ? parseFloat(a.average_rating) : 0;
            let ratingB = b.average_rating ? parseFloat(b.average_rating) : 0;
            return ratingB - ratingA; // For descending order
          }
        );
        setChildProducts(sortedChildProducts);
        const ratingData = responseData2.rating_info;
        setRatings([...ratingData, ...responseData2.external_ratings]);
      } catch (error) {
        setError("Failed to fetch products.");
      }
      setChildLoading(false);
    }

    fetchProduct2();
  }, [product, relationship, sortDirection, sortType]);

  useEffect(() => {
    if (!ratings || ratings.length === 0) {
      // Updated condition check for an empty array
      setNumberRatings(0);
      setAverageRating(null);
      setPredictedRating(0);
      return;
    }
    console.log("Ratings:", ratings);

    // Filter out ratings with a value greater than 0 and convert string ratings to numbers
    const positiveRatings = ratings
      .filter((rating) => parseFloat(rating.rating) > 0)
      .map((rating) => ({
        ...rating,
        rating: parseFloat(rating.rating), // Convert rating to number if it's a string
      }));

    // Set the number of positive ratings
    setNumberRatings(positiveRatings.length);

    // Calculate the average rating from positive ratings
    if (positiveRatings.length > 0) {
      const sumRatings = positiveRatings.reduce(
        (acc, curr) => acc + curr.rating,
        0
      );
      const averageRating = sumRatings / positiveRatings.length;
      setAverageRating(averageRating.toFixed(1)); // Keep as a string for display
    } else {
      setAverageRating(null); // Set to null if no positive ratings
    }

    // Predicted rating calculation
    if (relationship === "Everyone" || relationship == null) {
      const computedRating = calculatePredictedRating(positiveRatings); // Use positiveRatings
      setPredictedRating(computedRating);
    }
  }, [ratings, ratingUpdate]); // Ensure to include all dependencies used inside useEffect

  useEffect(() => {
    setRaterShow(100);
    if (ratings) {
      let sortedRatings = [...ratings];
      if (sortType === "Rating") {
        sortedRatings.sort((a, b) => {
          if (sortDirection === "ASC") {
            return a.rating - b.rating;
          }
          return b.rating - a.rating;
        });
      } else if (sortType === "Date") {
        sortedRatings.sort((a, b) => {
          if (sortDirection === "ASC") {
            return new Date(a.timestamp) - new Date(b.timestamp);
          }
          return new Date(b.timestamp) - new Date(a.timestamp);
        });
      } else if (sortType === "Similarity") {
        sortedRatings.sort((a, b) => {
          if (sortDirection === "ASC") {
            return a.similarity_score - b.similarity_score;
          }
          return b.similarity_score - a.similarity_score;
        });
      }
      setSortedRatings(sortedRatings);
    }
  }, [sortType, sortDirection, ratings]);

  const handleTagChange = (newTag) => {
    setRelationship(newTag);
  };

  const handleExternal = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleNextProduct = () => {
    setError(null); // Reset error state
    setReview(null); // Reset review state
    setPressable(null);
    setIsPlaying(false);
    setSelectedRating(null);
    if (activeTab === "Ratings" && selectedRating === null) {
      scrollViewRef.current.scrollLeft = 0;
    }
    // Swap next product into view
    // Wait one second before swapping
    navigate(`/products/${productIds[productCount + 1].product_id}`);
    setProductCount((prevCount) => prevCount + 1);
    // setTimeout(() => {
    //   setPreviousProduct(product);
    //   setPreviousRaters(raters);
    //   setPreviousExternalRaters(externalRaters);
    //   setPreviousPredictedRating(predictedRating);
    //   setPreviousAverageRating(averageRating);
    //   setProduct(nextProduct);
    //   setRaters(nextRaters);
    //   setExternalRaters(nextExternalRaters);
    //   setPredictedRating(nextPredictedRating);
    //   setAverageRating(nextAverageRating);
    //   setNextProduct(null); // Reset next product
    //   setNextRaters([]); // Reset next raters
    //   setNextExternalRaters([]); // Reset next external raters
    //   setNextPredictedRating(null); // Reset next predicted rating
    //   setSelectedRating(null); // Reset selected rating
    //   setProductCount((prevCount) => prevCount + 1);
    //   setPressable(true);
    // }, 200);
  };

  const handleGoBack = () => {
    if (productCount === 0) {
      setFlashMessageWithTimer(`Can't Go Back!`);
      // If previous product is null, do nothing
      return;
    }
    setError(null); // Reset error state
    setReview(null); // Reset review state
    setPressable(null);
    setFlashMessageWithTimer(`Going Back`);
    setSelectedRating(null);
    if (productCount > 0) {
      navigate(`/products/${productIds[productCount + -1].product_id}`);
      setProductCount((prevCount) => prevCount + -1);
    }
    // Swap previous product into view
    // Wait one second before swapping
    // setTimeout(() => {
    //   setNextProduct(product);
    //   setNextRaters(raters);
    //   setNextExternalRaters(externalRaters);
    //   setNextPredictedRating(predictedRating);
    //   setNextAverageRating(averageRating);
    //   setProduct(previousProduct);
    //   setRaters(previousRaters);
    //   setExternalRaters(previousExternalRaters);
    //   setPredictedRating(previousPredictedRating);
    //   setAverageRating(previousAverageRating);
    //   setPreviousProduct(null); // Reset previous product
    //   setSelectedRating(null);
    //   setProductCount((prevCount) => prevCount - 1);
    //   setPressable(true);
    // }, 200);
  };

  const handleSelectRating = (number, review = null) => {
    const date = new Date(); // Get the current date and time
    date.setHours(date.getHours() + 1); // Add one hour to the current time

    if (!userId && number > 0) {
      setShowLoginPopup(true);
      return;
    }

    if (number > 0) {
      setRatingCount(ratingCount + 1);
    }

    const payload = {
      product_id: product.product_id,
      user_id: userId,
      rating: number,
      timestamp: date.toISOString(), // Use the adjusted date
      category_id: product.category_id,
      review: review,
      screen: "RapidRate",
      device: "web",
    };

    fetch(`${apiUrl}/input_rating`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    handleNextProduct();
  };

  useEffect(() => {
    if (product && product.category_id === 5 && product.clip_url) {
      setClipUrl(product.clip_url);
    } else {
      setClipUrl(null);
      return;
    }

    const audioInstance = new Audio(product.clip_url);

    audioInstance.onloadeddata = () => {
      setAudio(audioInstance);
    };

    audioInstance.onerror = (error) => {
      console.log("Failed to load the sound", error);
      setAudio(null);
    };

    // Cleanup
    return () => {
      if (audioInstance) {
        audioInstance.pause();
        audioInstance.src = ""; // Release the audio object
      }
    };
  }, [product]);

  const playPauseSound = () => {
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio
          .play()
          .then(() => {
            console.log("Successfully started playing");
          })
          .catch((error) => {
            console.log("Playback failed due to audio decoding errors", error);
          });
      }
      setIsPlaying(!isPlaying);
    }
  };

  const genreKeywords = keywords.filter((item) => item.type === "genre");
  const platformKeywords = keywords.filter((item) => item.type === "platform");
  const cuisineKeywords = keywords.filter((item) => item.type === "cuisine");

  const orderedKeywords = [
    ...genreKeywords,
    ...platformKeywords,
    ...cuisineKeywords,
  ];

  function formatNumber(x) {
    if (x < 1000) {
      return x.toString();
    } else {
      let base = Math.pow(10, Math.floor(Math.log10(x)));
      let scaled;

      if (x < 1000000) {
        // Scale to nearest thousand
        scaled = Math.round(x / 1000);
        return scaled + "k";
      } else if (x < 1000000000) {
        // Scale to nearest million with one decimal
        scaled = Math.round((x / 1000000) * 10) / 10;
        return scaled + "M";
      } else {
        // Scale to nearest billion with one decimal
        scaled = Math.round((x / 1000000000) * 10) / 10;
        return scaled + "B";
      }
    }
  }

  const starContainerRef = useRef(null);
  const [hoverRating, setHoverRating] = useState(0);
  const ratingsDescriptions = [
    { num: -3, desc: "Blocked" },
    { num: -2, desc: "Skipped" },
    { num: -1, desc: "Wishlist" },
    { num: 0, desc: "Not Seen" },
    { num: 1, desc: "Awful" },
    { num: 2, desc: "Poor" },
    { num: 3, desc: "Underwhelming" },
    { num: 4, desc: "Mediocre" },
    { num: 5, desc: "Decent" },
    { num: 6, desc: "Good" },
    { num: 7, desc: "Very Good" },
    { num: 8, desc: "Great" },
    { num: 9, desc: "Incredible" },
    { num: 10, desc: "The Best" },
  ];

  const getRatingDescription = (rating) => {
    const item = ratingsDescriptions.find((desc) => desc.num === rating);
    return item ? item.desc : "Select a Rating"; // Default text
  };

  useEffect(() => {
    setSelectedRating(userRating);
  }, [userRating]);

  const updateRating = () => {
    // Use hoverRating if it's valid; otherwise, use selectedRating
    const newRating = hoverRating > 0 ? hoverRating : selectedRating;
    setSelectedRating(newRating);
  };

  const renderStars = () => {
    let stars = [];
    for (let i = 1; i <= 10; i++) {
      stars.push(
        <div
          key={i}
          className={`${styles.star} ${
            i <= (hoverRating || selectedRating) ? styles.highlightedStar : ""
          }`}
          onClick={updateRating}
          onMouseEnter={() => setHoverRating(i)}
          onMouseLeave={() => setHoverRating(0)}
          style={{
            color:
              i <= (hoverRating || selectedRating)
                ? "purple"
                : "rgb(230,230,230)",
          }}
        >
          <FontAwesomeIcon icon={faStar} size="3x" />
        </div>
      );
    }
    return stars;
  };

  // Function to handle setting and clearing the flash message
  const setFlashMessageWithTimer = (message) => {
    // Clear any existing timer
    if (timerId) {
      clearTimeout(timerId);
    }

    // Set the flash message immediately
    if (userId) {
      setFlashMessage(message); // Assumes `setFlashMessage` is defined to update some part of your component's state or context
    } else {
      setFlashMessage("Automatic account created!");
    }

    // Set a new timer to clear the message after 2 seconds
    const newTimerId = setTimeout(() => {
      setFlashMessage(null);
    }, 1000);

    // Update the timer ID state
    setTimerId(newTimerId);
  };

  const fetchProductIds = async () => {
    try {
      console.log("Fetching product ids");
      const productIdsString = productIds
        .map((product) => product.product_id)
        .join(",");
      const categoriesString =
        feedCategories && feedCategories.length > 0
          ? feedCategories.join(",")
          : "";
      const response = await fetch(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/rapid_product_ids?user_id=${userId}&everyone=${true}&category_ids=${categoriesString}&excluded_product_ids=${productIdsString}&latitude=${null}&longitude=${null}&new=${1}&startup=${"0"}`
      );
      const data = await response.json();
      setProductIds((prevIds) => {
        // Combine previous IDs and new IDs
        const combinedIds = [...prevIds, ...data];
        // Convert combined array to a Set to remove duplicates, then convert it back to an array
        const uniqueIds = Array.from(new Set(combinedIds));
        return uniqueIds;
      });

      if (productCount === -1) {
        setProductCount(0);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (productCount === -1) {
      return;
    }
    if ((productIds.length - productCount <= 3) & (productCount > 0)) {
      fetchProductIds();
      console.log("Fetching more products");
    }
  }, [productCount]);

  if (!product) {
    return <div className={styles.container}>Loading...</div>;
  }

  return (
    <div className={styles.container} ref={scrollViewRef}>
      <div
        className={styles.moreIconContainer}
        onClick={() => setShowModal(!showModal)}
      >
        <FontAwesomeIcon icon={faEllipsisH} className={styles.moreIcon} />
      </div>

      <div
        className={styles.arrowIconContainer}
        onClick={() => handleExternal(product.other_id)}
      >
        <FontAwesomeIcon
          icon={faSquareArrowUpRight}
          className={styles.linkIcon}
        />
      </div>

      {clipUrl && (
        <div className={styles.playContainer} onClick={playPauseSound}>
          <FontAwesomeIcon
            icon={isPlaying ? faPause : faPlay}
            className={styles.linkIcon}
          />
        </div>
      )}

      {/* <FontAwesomeIcon icon={faArrowLeft} className={styles.backArrow} onClick={handleGoBack} /> */}
      <img
        src={coverPhoto || product.product_photo || unknownImg}
        alt="cover"
        className={styles.coverPhoto}
      />

      {showModal && settingsModal()}
      {showReportModal && (
        <ReportModal
          isOpen={showReportModal}
          onClose={closeReportModal}
          onSubmit={closeReportModal}
          product_id={product_id}
          profile_id={null}
        />
      )}

      <div className={styles.image_container}>
        {ratingImages.length > 0 && (
          <div className={styles.images_arrows}>
            <button
              onClick={handleLeftClick}
              style={{
                color: imageSelection === 0 ? "grey" : "purple",
              }}
              disabled={imageSelection === 0}
            >
              <FaArrowLeft />
            </button>
            <button
              onClick={handleRightClick}
              style={{
                color:
                  imageSelection === ratingImages.length ? "grey" : "purple",
              }}
              disabled={imageSelection === ratingImages.length}
            >
              <FaArrowRight />
            </button>
          </div>
        )}
        <img
          className={styles.product_photo}
          src={
            imageSelection > 0
              ? ratingImages[imageSelection - 1].image_url // Selected image from ratingImages
              : product.product_photo // Fallback to product's image if available
              ? product.product_photo
              : unknownImg // Final fallback if no image is available
          }
          alt={product.name || "Unknown Product"}
        />
        {/* <div className={styles.uploadContainer}>
        <ProductImageUpload
          onFileUploaded={() => {
            window.alert("Images uploaded!");
          }}
          userId={userId}
          product_id={product_id}
        />
        </div> */}
        {ratingImages.length > 0 && (
          <p className={styles.image_count}>
            Image {imageSelection + 1} / {ratingImages.length + 1}
          </p>
        )}
      </div>

      <div className={styles.productMainInfo}>
        <h1 className={styles.page_title}>{product.name}</h1>

        <Link
          className={styles.creatorInfo}
          to={
            product.creator
              ? `/categories/${product.category_id}/${product.creator
                  .toLowerCase()
                  .replace(/\s+/g, "")}`
              : "#"
          }
        >
          <img
            className={styles.categoryIcon}
            src={product.icon_url}
            alt={"category"}
          />
          {product.creator && (
            <p className={styles.creator}>{product.creator || ""}</p>
          )}
          <p className={styles.creator}>{product.secondary_info || ""}</p>
        </Link>

        <div className={styles.mainKeywords}>
          {orderedKeywords.map((item) => (
            <Link to={`/categories/${product.category_id}/${item.keyword}`}>
              <div key={item.alias} className={styles.keyword}>
                {item.alias}
              </div>
            </Link>
          ))}
        </div>
      </div>

      <div className={styles.ratingContainer}>
        <FontAwesomeIcon
          icon={faChevronLeft}
          className={styles.arrowLeft}
          onClick={handleGoBack}
        />
        <h1 className={styles.selectedRating}>
          {hoverRating ||
            (selectedRating === -1
              ? "!"
              : selectedRating === -2
              ? "X"
              : selectedRating === -3
              ? "-"
              : selectedRating) ||
            "?"}
        </h1>
        <FontAwesomeIcon
          icon={faChevronRight}
          className={styles.arrowRight}
          onClick={() => {
            handleSelectRating(selectedRating || -2, review);
            if (selectedRating) {
              setFlashMessageWithTimer("Rating Sumbitted");
            } else {
              setFlashMessageWithTimer("Rating Skipped");
            }
          }}
        />
      </div>
      <p className={styles.ratingDescription}>
        {getRatingDescription(hoverRating || selectedRating)}
      </p>

      <div className={styles.starsContainer} ref={starContainerRef}>
        {renderStars()}
      </div>

      <div className={styles.ratingOptions}>
        <div className={styles.wishlistContainer}>
          <FontAwesomeIcon
            icon={faBan}
            className={styles.skip}
            onClick={() => {
              handleSelectRating(-2);
              setFlashMessageWithTimer("Skipped");
            }}
          />
          <p className={styles.wishlistText}>Skip</p>
        </div>
        <button
          className={styles.submitButton}
          onClick={() => {
            handleSelectRating(selectedRating, review);
            if (selectedRating) {
              setFlashMessageWithTimer("Rating Sumbitted");
            } else {
              setFlashMessageWithTimer("Rating Skipped");
            }
          }}
        >
          <h3 className={styles.submitText}>Submit Rating</h3>
        </button>
        <div className={styles.wishlistContainer}>
          <FontAwesomeIcon
            icon={faCircleCheck}
            className={styles.wishlist}
            onClick={() => {
              handleSelectRating(-1);
              setFlashMessageWithTimer("Added to Wishlist");
            }}
          />
          <p className={styles.wishlistText}>Wishlist</p>
        </div>
      </div>

      <div className={styles.reviewContainer}>
        <textarea
          className={styles.review}
          placeholder={`Write a review of ${product.name}`}
          value={review}
          onChange={(e) => setReview(e.target.value)}
          onInput={(e) => {
            e.target.style.height = "auto"; // Reset the height
            e.target.style.height = `${e.target.scrollHeight}px`; // Set height based on scrollHeight
          }}
        />
        <div className={styles.imageContainer}>
          <ProductImageUpload
            onFileUploaded={() => {
              window.alert("Images uploaded!");
            }}
            userId={userId}
            product_id={product_id}
          />
        </div>
      </div>

      <div className={styles.ratingInfo}>
        <p className={styles.ratingText}> Predicted Rating</p>
        <FontAwesomeIcon icon={faStar} className={styles.ratingStar} />
        <p className={styles.ratingText2}>
          {" "}
          {predictedRating > 0 ? predictedRating.toFixed(1) : "?"}{" "}
        </p>
        <p className={styles.ratingText3}> ({predictedRaters})</p>
      </div>

      <div className={styles.ratingInfo}>
        <p className={styles.ratingText}> Average Rating</p>
        <FontAwesomeIcon icon={faStar} className={styles.ratingStar} />
        <p className={styles.ratingText2}>
          {" "}
          {Number.isNaN(parseFloat(averageRating)) ||
          averageRating === null ||
          averageRating == 0
            ? "?"
            : parseFloat(averageRating).toFixed(1)}{" "}
        </p>
        <p className={styles.ratingText3}> ({numberRatings})</p>
      </div>

      {/* {product.creator && (
        <Link
          to={`/categories/${product.category_id}/${product.creator
            .toLowerCase()
            .replace(/\s+/g, "")}`}
        >
          <div className={styles.creator}>By {product.creator}</div>
        </Link>
      )} */}

      {/* */}
      {/* 
      {showCopyMessage && (
        <div className={styles.copy_message}>Link Copied to Clipboard!</div>
      )} */}
      {/* <div className={styles.categoryIconContainer}>

      </div> */}

      {/* <div className={styles.ratingContainer}>
        <div className={styles.subRatingContainer}>
          <h4 className={styles.averageRating}>
            {Number.isNaN(parseFloat(averageRating)) ||
            averageRating === null ||
            averageRating == 0
              ? "?"
              : parseFloat(averageRating).toFixed(1)}
          </h4>
          <h4 className={styles.ratingLabel}> Avg Rating</h4>
          <h4 className={styles.ratingCount}> ({numberRatings} ratings)</h4>
        </div>

        <div className={styles.subRatingContainer}>
          <h4 className={styles.averageRating}>
            {predictedRating === null || predictedRating == 0
              ? "?"
              : parseFloat(predictedRating).toFixed(1)}
          </h4>
          <h4 className={styles.ratingLabel}> Predicted Rating</h4>
          <h4 className={styles.ratingCount}> ({predictedRaters} Raters)</h4>
        </div>

        <div className={styles.subRatingContainer2}>
          <GiveRating
            productId={product_id}
            categoryId={product.category_id}
            productTitle={product.name}
            userRating={userRating}
            userId={userId}
            handleRating={handleRatingChange}
            screen={"Product"}
          />
          {userRatingDate && (
            <h4 className={styles.ratingDate}>
              {formatDistanceToNow(userRatingDate, {
                addSuffix: true,
              }).replace("about ", "")}
            </h4>
          )}
        </div>
      </div> */}

      <div className={styles.tabsContainer}>
        {["Description", "Raters", "Tags"].map((tab) => (
          <button
            key={tab}
            className={`${styles.tab} ${
              activeTab === tab ? styles.activeTab : ""
            }`}
            onClick={() => setActiveTab(tab)}
            style={{ flex: 1 }}
          >
            <span
              className={
                activeTab === tab ? styles.activeTabText : styles.tabText
              }
            >
              {tab}
            </span>
            {activeTab === tab && <div className={styles.activeTabUnderline} />}
          </button>
        ))}
      </div>

      {/* <div className={styles.otherActions}>

        {userRating > 0 &&
          (userReview ? (
            <p
              className={styles.reviewLink}
              onClick={() => handleReviewClick(-1)}
            >
              Your Review
            </p>
          ) : (
            <p
              className={styles.reviewLink}
              onClick={() => handleReviewClick(-1)}
            >
              Write a Review
            </p>
          ))}
      </div> */}

      <div className="product-page-right-review">
        <ReviewModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          productName={product.name}
          secondaryInfo={product.secondary_info}
          userPhoto={modalPhoto || userPhoto}
          username={modalUsername || username}
          onSubmit={handleModalSubmit}
          userId={modalUserId || userId}
          type={modalUserId == userId ? "self" : "other"}
          productId={product.product_id}
          review={modalReview || userReview}
          rating={modalRating || userRating}
          categoryIcon={product.icon_url}
          timestamp={modalTimestamp || userRatingDate}
        />
      </div>

      {product.description &&
        product.description.length > 3 &&
        activeTab == "Description" && (
          <p className={styles.description}>{product.description}</p>
        )}

      {/* {userId == 500 && (
        <div className="product-delete">
          <button onClick={handleDeleteProduct}>Delete Product</button>
        </div>
      )} */}

      {activeTab === "Tags" && (
        <ProductKeywords
          className={styles.keywordsContainer}
          keywords={keywords}
          categoryId={product.category_id}
        />
      )}

      {/* <label
        title="Add relevant keywords for better searchability."
        className="product-page-add-keywords"
      >
        Add Tags:
        <input
          value={currentKeyword}
          onChange={(e) => setCurrentKeyword(e.target.value)}
        />
        <button type="button" onClick={addKeywords}>
          Add
        </button>
      </label> */}

      {activeTab === "Raters" && (
        <div className={styles.ratersContainer}>
          <div className={styles.raterGalleryHeader}>
            <div className={styles.sortOptions}>
              <SimpleDropdown
                options={tags}
                selectedOption={relationship}
                onSelect={handleTagChange}
                name="Users"
              />
              <SimpleDropdown
                options={sortOptions}
                selectedOption={sortType}
                onSelect={handleSelect}
                name="Sort Type"
              />
              <button
                className={styles.sortButton}
                onClick={() =>
                  setSortDirection(sortDirection === "ASC" ? "DESC" : "ASC")
                }
              >
                {sortDirection === "ASC" ? "↑" : "↓"}
              </button>

              <div className={styles.checkboxFilters}>
                <div className={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    checked={showOnlyReviewers}
                    onChange={() => setShowOnlyReviewers(!showOnlyReviewers)}
                  />
                  Reviewed
                </div>

                <div className={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    checked={showOnlyExternal}
                    onChange={() => setShowOnlyExternal(!showOnlyExternal)}
                  />
                  External
                </div>
              </div>
            </div>
          </div>
          <div className={styles.productRaterGallery}>
            {sortedRatings
              .filter(
                (rater) =>
                  (!showOnlyReviewers || (showOnlyReviewers && rater.review)) &&
                  (!showOnlyExternal ||
                    (showOnlyExternal && rater.user_id < 500))
              )
              .slice(0, raterShow) // Show only the first {raterShow} raters
              .map((rater) => {
                // Conditionally change class name
                const cardClassName = rater.review
                  ? styles.cardWithReview
                  : styles.productRaterCard;

                return (
                  <div key={rater.user_id} className={cardClassName}>
                    <div className={styles.cardContainer}>
                      <h4>{rater.username}</h4>
                      {rater.similarity_score > 0 ? (
                        <h6>Similarity: {rater.similarity_score}%</h6>
                      ) : (
                        <h6>{""}</h6>
                      )}
                      <Link
                        to={
                          rater.user_id < 500
                            ? `/ExternalProfile/${rater.user_id}`
                            : `/profiles/${rater.user_id}`
                        }
                      >
                        <img src={rater.user_photo} alt="User" />
                      </Link>
                      <h5 className={styles.raterRating}>{rater.rating}</h5>
                      {rater.timestamp && (
                        <h6 className={styles.raterTimestamp}>
                          {" "}
                          {formatDistanceToNow(rater.timestamp, {
                            addSuffix: true,
                          }).replace("about ", "")}
                        </h6>
                      )}
                      {rater.vote_count && (
                        <h6 className={styles.raterTimestamp}>
                          External Average <br />
                          {formatNumber(rater.vote_count)} Ratings
                        </h6>
                      )}
                    </div>
                    {rater.review && (
                      <div
                        onClick={() => {
                          handleReviewClick(rater);
                          setIsModalOpen(true);
                        }}
                        className={styles.reviewText}
                      >
                        {rater.review}
                      </div>
                    )}
                    {rater.review && (
                      <div className={styles.likeContainer}>
                        <FontAwesomeIcon
                          icon={faThumbsUp}
                          className={styles.likeButton}
                          color={rater.user_liked ? "purple" : "grey"}
                          onClick={() =>
                            handleLike(
                              userId,
                              rater.rating_id,
                              rater.user_liked,
                              rater.user_id
                            )
                          }
                        />
                        <p className={styles.likeCount}>{rater.likes}</p>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          <div className="product-rating-more-button">
            {ratings.length > raterShow && (
              <button
                onClick={() => setRaterShow((prevCount) => prevCount + 100)}
              >
                Show More
              </button>
            )}
          </div>
        </div>
      )}
      {activeTab === "SubProducts" && (
        <div>
          <h2>Sub Products (Coming Soon)</h2>
        </div>
      )}

      {activeTab === "SimilarProducts" && (
        <div>
          <h2>Similar Products (Coming Soon)</h2>
        </div>
      )}

      {activeTab === "Stats" && (
        <div>
          <h2>Product Stats (Coming Soon)</h2>
        </div>
      )}
    </div>
  );
}

export default ProductPage;
