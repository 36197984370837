import React, { useState, useEffect, useContext, useRef } from "react";
import ReactSlider from "react-slider";
import "./CategoryPage.css";
import SearchKeywords from "../components/SearchKeywords";
import { FaTimes } from "react-icons/fa"; // To use 'X' icon for removing keywords
import RatingDropdown from "../components/RatingDropdown";
import GiveRating from "../components/GiveRating";
import { Link, useParams } from "react-router-dom";
import { UserContext } from "../components/UserContext";
import styles from "./CategoryPage.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCaretLeft,
  faFilter,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import SimpleDropdown from "../components/SimpleDropdown";
import SimpleDropdownS from "../components/SimpleDropdownShort";
import InfiniteScroll from "react-infinite-scroll-component";
import Autocomplete from "react-google-autocomplete";

function CategoryPage() {
  const [products, setProducts] = useState([]);
  const { category_id, keyword_id } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(category_id);
  const [selectedGender, setSelectedGender] = useState("None");
  const [ageRange, setAgeRange] = useState([0, 100]); // Adjusted for two-tailed slider
  const [ageRangeDisplay, setAgeRangeDisplay] = useState([0, 100]); // Adjusted for two-tailed slider
  const [error, setError] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState("Everyone");
  const [tags, setTags] = useState([]);
  const [sortType, setSortType] = useState("Rating");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [userCount, setUserCount] = useState(0);
  const [ratingCount, setRatingCount] = useState(0);
  const [productCount, setProductCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [keywordsList, setKeywordsList] = useState([]); // To store selected keywords
  const [allKeywords, setAllKeywords] = useState([]); // To store selected keywords
  const [isSeen, setIsSeen] = useState(false); // Toggle switch for 'Seen'
  const [yearRange, setYearRange] = useState([null, null]); // Adjusted for two-tailed slider
  const [yearRangeDisplay, setYearRangeDisplay] = useState([null, null]); // Adjusted for two-tailed slider
  const { userId } = useContext(UserContext);
  const [categories, setCategories] = useState([]);
  const [inputTag, setInputTag] = useState("");
  const [filteredTags, setFilteredTags] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("None");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [unratedOnly, setUnratedOnly] = useState(false);
  const [minRaters, setMinRaters] = useState(0);
  const [showYearRange, setShowYearRange] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [categoryName, setCategoryName] = useState("Products");
  const [showMoreVisible, setShowMoreVisible] = useState(true);
  const [noProducts, setNoProducts] = useState(false);
  const [minYear, setMinYear] = useState(null);
  const [maxYear, setMaxYear] = useState(null);
  const [isDataReady, setIsDataReady] = useState(false);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [maxDistance, setMaxDistance] = useState("");
  const [ratedOnly, setRatedOnly] = useState(false);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const scrollViewRef = React.useRef(null);
  const [showFilter, setShowFilter] = useState(false);
  const [sortOptions, setSortOptions] = useState(["Rating", "Popularity"]);
  const [searchInput, setSearchInput] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState("Explore");
  const [topRated, setTopRated] = useState([]);
  const [popularInNetwork, setPopularInNetwork] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [featuredUsers, setFeaturedUsers] = useState([]);
  const [applyFilterCount, setApplyFilterCount] = useState(0);
  const [categoryIcon, setCategoryIcon] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const unknownImg =
    "https://s3.eu-west-2.amazonaws.com/rated.images/unknown.png";
  const scrollRef1 = useRef(null);
  const scrollRef2 = useRef(null);
  const scrollRef3 = useRef(null);
  const scrollRef4 = useRef(null);

  const [leftArrowVisible1, setLeftArrowVisible1] = useState(false);
  const [rightArrowVisible1, setRightArrowVisible1] = useState(true);
  const [leftArrowVisible2, setLeftArrowVisible2] = useState(false);
  const [rightArrowVisible2, setRightArrowVisible2] = useState(true);
  const [leftArrowVisible3, setLeftArrowVisible3] = useState(false);
  const [rightArrowVisible3, setRightArrowVisible3] = useState(true);
  const [leftArrowVisible4, setLeftArrowVisible4] = useState(false);
  const [rightArrowVisible4, setRightArrowVisible4] = useState(true);

  const [empty, setEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const scroll = (scrollOffset, number) => {
    let scrollRef;
    let setRightArrowVisible;
    let setLeftArrowVisible;

    switch (number) {
      case 1:
        scrollRef = scrollRef1;
        setRightArrowVisible = setRightArrowVisible1;
        setLeftArrowVisible = setLeftArrowVisible1;
        break;
      case 2:
        scrollRef = scrollRef2;
        setRightArrowVisible = setRightArrowVisible2;
        setLeftArrowVisible = setLeftArrowVisible2;
        break;
      case 3:
        scrollRef = scrollRef3;
        setRightArrowVisible = setRightArrowVisible3;
        setLeftArrowVisible = setLeftArrowVisible3;
        break;
      case 4:
        scrollRef = scrollRef4;
        setRightArrowVisible = setRightArrowVisible4;
        setLeftArrowVisible = setLeftArrowVisible4;
        break;
      default:
        return; // Exit if invalid number
    }

    if (!scrollRef.current) return;

    // Calculate the next potential scroll position based on current scroll offset
    const potentialScrollLeft = scrollRef.current.scrollLeft + scrollOffset;

    // Perform the scroll
    scrollRef.current.scrollBy({
      left: scrollOffset,
      behavior: "smooth",
    });

    // Determine if the right arrow should be visible
    const maxScrollLeft =
      scrollRef.current.scrollWidth - scrollRef.current.clientWidth;
    if (scrollOffset > 0 && potentialScrollLeft >= maxScrollLeft) {
      setRightArrowVisible(false);
    } else {
      setRightArrowVisible(true);
    }

    // Determine if the left arrow should be visible
    if (scrollOffset < 0 && potentialScrollLeft <= 0) {
      setLeftArrowVisible(false);
    } else {
      setLeftArrowVisible(true);
    }
  };

  const requestLocation = () => {
    console.log("Requesting location...");
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        // Success callback
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          setErrorMessage(null); // Clear any previous errors
        },
        // Error callback
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              setErrorMessage("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              setErrorMessage("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              setErrorMessage("The request to get user location timed out.");
              break;
            case error.UNKNOWN_ERROR:
            default:
              setErrorMessage("An unknown error occurred.");
              break;
          }
        }
      );
    } else {
      setErrorMessage("Geolocation is not supported by this browser.");
    }
  };

  const handleSearchInput = (text) => {
    setSearchInput(text);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const scrollToTop = () => {
    scrollViewRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleSelect = (option) => {
    setSortType(option);
    if (option !== "Distance") {
      setSortType(option);
    }
    if (option === "Distance") {
      setSortOrder("ASC");
    } else {
      setSortOrder("DESC");
    }
  };

  async function fetchLanding(category_id) {
    if (category_id == null) return;
    if (categories.length === 0) return;
    try {
      const response = await fetch(
        `${apiUrl}/category_landing?category_id=${category_id}&user_id=${userId}`
      );
      const data = await response.json();
      setTopRated(data.top_rated_products);
      setPopularInNetwork(data.popular_unrated_products);
      setFeaturedProducts(data.featured_products);
      setFeaturedUsers(data.featured_users);
    } catch (error) {
      console.error("Error fetching landing:", error);
    }
  }

  // Debounce function setup
  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchQuery(searchInput);
    }, 500); // 500 milliseconds delay

    return () => clearTimeout(handler); // Cleanup on effect re-run, which happens on searchInput change
  }, [searchInput]); // Effect runs whenever searchInput changes

  const handleRatingChange = (productId, newRating) => {
    // Handle success case
    // Find the updated product and change its user_rating.
    const updatedProducts = products.map((product) => {
      if (product.product_id === productId) {
        return {
          ...product,
          user_rating: newRating, // Update the user_rating.
        };
      }
      return product; // Leave all other products unchanged.
    });

    // Update the state with the modified products array.
    setProducts(updatedProducts);
  };

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  useEffect(() => {
    async function fetchTags() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_new_tags?userId=${userId}`
        );
        const data = await response.json();
        setTags(["Everyone", ...data]);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    }

    fetchTags();
  }, []); // Empty dependency array, only run once on mount.

  useEffect(() => {
    async function fetchKeywords() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_keywords?category_id=${selectedCategory}`
        );
        const data = await response.json();
        setAllKeywords(data);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    }

    fetchKeywords();
  }, [selectedCategory]);

  useEffect(() => {
    async function fetchCategories() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_all_categories`
        );
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    }

    fetchCategories();
  }, []); // Empty dependency array, only run once on mount.

  // New useEffect that runs when 'categories' state is updated.
  useEffect(() => {
    if (categories.length > 0) {
      // Only run if 'selectedCategory' is valid and categories have been loaded.
      handleCategoryChange(selectedCategory);
    }
  }, [categories]); // Dependency array containing 'categories' to re-run when it changes.

  useEffect(() => {
    async function fetchCountries() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_used_countries`
        );
        const data2 = await response.json();
        setCountries(data2);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    }

    fetchCountries();
  }, []); // Empty dependency array, only run once on mount.

  useEffect(() => {
    async function fetchProducts() {
      if (!isDataReady) {
        return;
      }
      try {
        setProducts([]); // Clear the products array
        const response = await fetch(
          `${
            process.env.REACT_APP_BACKEND_URL
          }/get_products2?category_id=${category_id}&gender=${selectedGender}&min_age=${
            ageRange[0]
          }&max_age=${
            ageRange[1]
          }&relationship_type=${selectedGroup}&sort_type=${sortType}&userId=${userId}&sort_order=${sortOrder}&keywords=${keywordsList}&unrated_only=${unratedOnly}&year_min=${
            yearRange[0]
          }&year_max=${
            yearRange[1]
          }&country=${selectedCountry}&page=1&page_size=${pageSize}&min_raters=${minRaters}&latitude=${
            location.latitude
          }&longitude=${
            location.longitude
          }&max_distance=${maxDistance}&location=${showLocation}&rated_only=${ratedOnly}&search_query=${searchQuery}&web=${true}`
        );

        const data = await response.json();

        const productsArray = data;
        if (productsArray.length < pageSize) {
          setEmpty(true);
          setPage(0);
          //fetchMoreProducts();
        }
        if (productsArray.length > 0) {
          setNoProducts(false);
        } else {
          setEmpty(true);
          setPage(0);
          //fetchMoreProducts();
        }
        setProducts(productsArray);
        setError(null);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
        setError("Failed to fetch products.");
        setNoProducts(true);
        setHasMore(false);
      }
    }

    fetchProducts();
  }, [
    selectedCategory,
    selectedGender,
    ageRange,
    selectedGroup,
    sortType,
    selectedCountry,
    keywordsList,
    isSeen,
    yearRange,
    unratedOnly,
    minRaters,
    sortOrder,
    sortType,
    isDataReady,
    searchQuery,
    maxDistance,
  ]); // Re-run when any of these change

  useEffect(() => {
    if (page === 0) {
      fetchMoreProducts();
    }
  }, [page]);

  useEffect(() => {
    // Check if the window width is less than 768px on mount
    if (window.innerWidth < 1000) {
      navigate("/WelcomeM"); // Assuming '/mobile' is the route for LandingMobile
    }
  }, [navigate]);

  async function fetchMoreProducts() {
    if (!hasMore || isLoading) {
      return;
    }
    setIsLoading(true);
    // Increment page for pagination
    const nextPage = page + 1;

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/get_products2?category_id=${category_id}&gender=${selectedGender}&min_age=${
          ageRange[0]
        }&max_age=${
          ageRange[1]
        }&relationship_type=${selectedGroup}&sort_type=${sortType}&userId=${userId}&sort_order=${sortOrder}&keywords=${keywordsList}&unrated_only=${unratedOnly}&year_min=${
          yearRange[0]
        }&year_max=${
          yearRange[1]
        }&country=${selectedCountry}&page=${nextPage}&page_size=${pageSize}&min_raters=${minRaters}&latitude=${
          location.latitude
        }&longitude=${
          location.longitude
        }&max_distance=${maxDistance}&location=${showLocation}&rated_only=${ratedOnly}&search_query=${searchQuery}&$web=${true}&empty=${empty}`
      );

      const data = await response.json();
      setIsLoading(false);

      const productsArray = data;
      if (productsArray.length < pageSize && !empty) {
        console.log("setting empty");
        setEmpty(true);
      }

      if (productsArray.length < pageSize && empty) {
        console.log("setting has more false");
        setHasMore(false);
      }

      setProducts((prevProducts) => [...prevProducts, ...productsArray]);
      setPage(nextPage); // Only increment page on successful fetch
      setError(null);
    } catch (error) {
      console.error("Error fetching products:", error);
      setError("Failed to fetch products.");
    }
  }
  const filterTags = (input) => {
    setInputTag(input);
    setFilteredTags(
      allKeywords.filter((tag) =>
        tag.toLowerCase().includes(input.toLowerCase())
      )
    );
    setShowDropdown(true);
  };

  const addKeywordToList = (keyword) => {
    console.log("test");
    if (!keywordsList.includes(keyword))
      setKeywordsList([...keywordsList, keyword]);
  };

  const removeKeywordFromList = (keyword) => {
    setKeywordsList(keywordsList.filter((k) => k !== keyword));
  };

  const filterKeywords = (inputValue) => {
    // Remove all spaces from inputValue
    setInputTag(inputValue);
    const trimmedInputValue = inputValue.replace(/\s+/g, "");

    if (trimmedInputValue.length >= 3) {
      const filtered = allKeywords
        .filter((keyword) =>
          keyword.toLowerCase().startsWith(trimmedInputValue.toLowerCase())
        )
        .slice(0, 20); // Limit to the first 20 matches
      setFilteredTags(filtered);
      setShowDropdown(filtered.length > 0);
    } else {
      setFilteredTags([]);
      setShowDropdown(false);
    }
  };

  // Debounce inputTag changes
  useEffect(() => {
    const handler = setTimeout(() => {
      filterKeywords(inputTag);
    }, 300); // 300ms delay for debouncing

    // Cleanup function to cancel the timeout
    return () => clearTimeout(handler);
  }, [inputTag, allKeywords]); // Re-run the effect

  const handleTagClick = (tag) => {
    setInputTag("");
    setShowDropdown(false);
    addKeywordToList(tag);
  };

  const resetFilters = () => {
    setRatedOnly(false);
    setUnratedOnly(false);
    setMinRaters(0);
    setYearRange([null, null]);
    setYearRangeDisplay([null, null]);
    setKeywordsList([]);
    setSearchInput("");
    setMaxDistance("");
    setSearchQuery("");
  };

  const handleRatedChange = (e) => {
    setRatedOnly(e.target.checked);
    if (e.target.checked) {
      setUnratedOnly(false);
    }
  };

  const handleUnratedChange = (e) => {
    setUnratedOnly(e.target.checked);
    if (e.target.checked) {
      setRatedOnly(false);
    }
  };

  const handleCategoryChange = (category_id) => {
    console.log("starting category change");
    scrollToTop();
    setIsDataReady(false);
    resetFilters();
    setSelectedCategory(category_id);
    setAllKeywords([]);
    if (keyword_id) {
      setKeywordsList([keyword_id]);
    }
    const selectedCategoryObj = categories.find(
      (category) => category.category_id == category_id
    );

    if (selectedCategoryObj) {
      setCategoryName(selectedCategoryObj.name);
      setCategoryIcon(selectedCategoryObj.icon_url);
    }
    if (selectedCategoryObj && selectedCategoryObj.min_year !== null) {
      setShowYearRange(true);
      setYearRangeDisplay([
        selectedCategoryObj.min_year,
        selectedCategoryObj.max_year,
      ]);
      setYearRange([
        selectedCategoryObj.min_year,
        selectedCategoryObj.max_year,
      ]);
      setMinYear(selectedCategoryObj.min_year);
      setMaxYear(selectedCategoryObj.max_year);
    } else {
      setShowYearRange(false);
    }

    // Use an async IIFE to handle location permission and fetching
    (async () => {
      if (selectedCategoryObj && selectedCategoryObj.location) {
        setShowLocation(true);
        setSortOptions(["Rating", "Popularity", "Distance"]);
        // Wait for location permission and fetching
        requestLocation();
      } else {
        setShowLocation(false);
        setSortOptions(["Rating", "Popularity"]);
      }

      // Only proceed to this line once the above async operations are complete
      setIsDataReady(true);
      setApplyFilterCount(applyFilterCount + 1);
      fetchLanding(category_id);
    })();
  };

  const handleGroup = (option) => {
    setSelectedGroup(option);
    // setApplyFilterCount(applyFilterCount + 1);
  };

  const LoadingSpinner = () => <div className={styles.purpleSpinner}></div>;

  const LocationSearchScreen = () => {

    return (
      <div style={{ padding: "20px" }}>
        <Autocomplete
          placeholder='Current Location'
          apiKey={process.env.REACT_APP_GOOGLE_KEY}
          style={{ width: "90%", backgroundColor: 'rgb(40,40,40)', border: 'none', color: 'white', height: '32px', marginTop: '-30px', outline: 'none'}}
          onPlaceSelected={(place) => {
            console.log(place);
            setLocation({
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng(),
            });
          }}
          fields={[
            "geometry.location",
          ]}
          options={{
            types: ["establishment"], // This includes restaurants, museums, etc.
          }}
        />
      </div>
    );
  };

  return (
    <div className={styles.container} ref={scrollViewRef}>
      <h1 className={styles.page_title}>{categoryName}</h1>

      <div className={styles.backIconContainer} onClick={handleBackClick}>
        <FontAwesomeIcon icon={faArrowLeft} className={styles.backArrow} />
      </div>

      <img
        src={categoryIcon}
        alt="Category Icon"
        className={styles.categoryIcon}
      />

      <div className={styles.tabs}>
        <button
          className={`${styles.tabButton} ${
            activeTab === "Explore" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("Explore")}
        >
          Explore
        </button>

        <button
          className={`${styles.tabButton} ${
            activeTab === "Top Picks" ? styles.active : ""
          }`}
          onClick={() => setActiveTab("Top Picks")}
        >
          Top Picks
        </button>
      </div>

      {activeTab === "Explore" && (
        <div>
          <div
            className={styles.filterContainer}
            onClick={() => {
              scrollToTop();
              setShowFilter(!showFilter);
            }}
          >
            <FontAwesomeIcon icon={faFilter} className={styles.filterButton} />
          </div>

          <div className={styles.filtersTop}>
            <SimpleDropdownS
              options={tags}
              selectedOption={selectedGroup}
              onSelect={handleGroup}
              name="Users"
            />

            <div className={styles.sortContainer}>
              <SimpleDropdown
                options={sortOptions}
                selectedOption={sortType}
                onSelect={handleSelect}
                name="Sort Type"
              />
            </div>
            <button
              className={styles.sortButton}
              onClick={() => setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC")}
            >
              {sortOrder === "ASC" ? "↑" : "↓"}
            </button>
            <input
              className={styles.search_input}
              placeholder="Search Products"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>

          {showFilter && (
            <div className={styles.filtersContainer}>
              <div className={styles.product_filters_bottom}>
                <div className={styles.checkboxFilters}>
                  <div className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={ratedOnly}
                      onChange={handleRatedChange}
                    />
                    Rated
                  </div>
                  <div className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={unratedOnly}
                      onChange={handleUnratedChange}
                    />
                    Unrated
                  </div>
                </div>

                <input
                  className={styles.min_raters_input}
                  type="number"
                  placeholder="Min Raters"
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    if (!isNaN(value)) {
                      setMinRaters(value);
                    } else {
                      setMinRaters("");
                    }
                  }}
                  onKeyPress={(e) => {
                    // Prevent default if not a number or if key is the dot (for decimal numbers)
                    if (!/[0-9]/.test(e.key) || e.key === ".") {
                      e.preventDefault();
                    }
                  }}
                  step="1"
                  min="0"
                />

                {showYearRange && (
                  <div className={styles.yearFilters}>
                    <input
                      className={styles.min_raters_input}
                      type="number"
                      placeholder="Min Year"
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        if (
                          !isNaN(value) &&
                          value <= yearRange[1] &&
                          value > 1000
                        ) {
                          setYearRange([value, yearRange[1]]);
                        } else {
                          setYearRange(null, yearRange[1]);
                        }
                      }}
                      onKeyPress={(e) => {
                        // Prevent default if not a number or if key is the dot (for decimal numbers)
                        if (!/[0-9]/.test(e.key) || e.key === ".") {
                          e.preventDefault();
                        }
                      }}
                      step="1"
                      min="0"
                    />

                    <input
                      className={styles.min_raters_input}
                      type="number"
                      placeholder="Max Year"
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        if (
                          !isNaN(value) &&
                          value >= yearRange[0] &&
                          value > 1000
                        ) {
                          setYearRange([yearRange[0], value]);
                        } else {
                          setYearRange([yearRange[0], null]);
                        }
                      }}
                      onKeyPress={(e) => {
                        // Prevent default if not a number or if key is the dot (for decimal numbers)
                        if (!/[0-9]/.test(e.key) || e.key === ".") {
                          e.preventDefault();
                        }
                      }}
                      step="1"
                      min="0"
                    />
                  </div>
                )}

                {/* {showLocation && (
                  <div className={styles.locationFilters}>
                    <LocationSearchScreen />

                    <input
                      className={styles.min_raters_input}
                      type="number"
                      placeholder="Max Distance (miles)"
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10);
                        if (!isNaN(value)) {
                          setMaxDistance(value);
                        } else {
                          setMaxDistance("");
                        }
                      }}
                      onKeyPress={(e) => {
                        // Prevent default if not a number or if key is the dot (for decimal numbers)
                        if (!/[0-9]/.test(e.key) || e.key === ".") {
                          e.preventDefault();
                        }
                      }}
                      step="1"
                      min="0"
                    />
                  </div>
                )} */}
              </div>
              <div className={styles.product_filters_bottom}>
                <div className={styles.input_dropdown}>
                  <input
                    className={styles.keyword_input}
                    value={inputTag}
                    onChange={(e) => filterKeywords(e.target.value)}
                    onFocus={() => setShowDropdown(true)}
                    onBlur={() => setTimeout(() => setShowDropdown(false), 100)} // Delay to allow click event
                    placeholder="Search and filter by #tags"
                  />
                  {showDropdown && inputTag && (
                    <div className={styles.customDropdown}>
                      {filteredTags.map((tag) => (
                        <div
                          className={styles.dropdownItem}
                          key={tag}
                          onMouseDown={() => handleTagClick(tag)}
                        >
                          {tag}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <p className={styles.resetFilter} onClick={() => resetFilters()}>
                Reset Filters
              </p>
            </div>
          )}

          {keywordsList.length > 0 && (
            <div className={styles.keywords_list}>
              {keywordsList.map((k) => (
                <span className={styles.keywordBubble} key={k}>
                  {k}{" "}
                  <FaTimes
                    className={styles.keywordBubbleButton}
                    onClick={() => removeKeywordFromList(k)}
                  />
                </span>
              ))}
            </div>
          )}

          <div className={styles.gallery}>
            {Array.isArray(products) && products.length > 0 ? (
              <InfiniteScroll
                className={styles.infContainer}
                dataLength={products.length}
                next={fetchMoreProducts}
                loader={<LoadingSpinner />}
                hasMore={hasMore}
                endMessage={
                  <p style={{ textAlign: "center", color: "white" }}>
                    <b>
                      You have seen all matching products. Try adjusting your
                      filters to explore more content!
                    </b>
                  </p>
                }
              >
                {products.map((product, index) => (
                  <div
                    key={product.product_id}
                    className={styles.card}
                    data-rank={index + 1}
                  >
                    <div className={styles.galleryCard}>
                      <div className={styles.header}>
                        <div className={styles.titleBlock}>
                          <h3 className={styles.title}>
                            {product.name} ({product.secondary_info})
                          </h3>
                        </div>
                        <div className={styles.ratingBlock}>
                          <h4 className={styles.averageRating}>
                            {product.average_rating === null
                              ? "?"
                              : product.average_rating % 1 === 0 &&
                                product.average_rating !== 10
                              ? `${product.average_rating}.0`
                              : product.average_rating}
                          </h4>
                          <h4 className={styles.numberRatings}>
                            ({product.number_ratings} Ratings)
                          </h4>
                        </div>
                      </div>
                      <div className={styles.middleCardContent}>
                        <div className={styles.imageContainer}>
                          <Link to={`/products/${product.product_id}`}>
                            <img
                              className={styles.productImage}
                              src={
                                product.image_url
                                  ? product.image_url
                                  : unknownImg
                              }
                              alt={product.name}
                            />
                          </Link>
                        </div>
                        <div className={styles.ratersContainer}>
                          {product.raters.slice(0,18).map((rater) => (
                            <div
                              className={styles.userCard}
                              key={rater.username}
                            >
                              <Link
                                to={
                                  rater.user_id < 500
                                    ? `/ExternalProfile/${rater.user_id}`
                                    : `/profiles/${rater.user_id}`
                                }
                              >
                                <img
                                  className={styles.userPhoto}
                                  src={rater.photo_url}
                                  alt={rater.username}
                                  title={rater.username} // This will display username on hover
                                />
                              </Link>
                              <span className={styles.userRating}>
                                {rater.rating}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className={styles.giveRatingContainer}>
                        <GiveRating
                          productId={product.product_id}
                          productTitle={product.name}
                          userRating={product.user_rating}
                          userId={userId}
                          handleRating={handleRatingChange}
                          categoryId={category_id}
                          screen={"Category"}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            ) : (
              <div className={styles.noResults}>
                {noProducts ? (
                  <h3>No matching products found.</h3>
                ) : (
                  <div className={styles.purpleSpinner}></div>
                )}
              </div>
            )}
          </div>
          {/* <div className={styles.show_more}>
            {hasMore && showMoreVisible && (
              <button
                onClick={() => {
                  fetchMoreProducts();
                }}
              >
                Show More
              </button>
            )}
          </div> */}
        </div>
      )}

      {activeTab === "Top Picks" && (
        <div className={styles.content}>
          {popularInNetwork.length > 0 && (
            <div className={styles.contentContainer}>
              <div>
                {userId > 0 ? (
                  <h2 className={styles.contentHeader}>
                    New From Your Network
                  </h2>
                ) : (
                  <h2 className={styles.contentHeader}>Most Popular</h2>
                )}
                {popularInNetwork.length > 4 && leftArrowVisible1 && (
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    color="white"
                    onClick={() => scroll(-600, 1)}
                    className={styles.scrollLeft}
                  />
                )}
                {popularInNetwork.length > 4 && rightArrowVisible1 && (
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    color="white"
                    onClick={() => scroll(600, 1)}
                    className={styles.scrollRight}
                  />
                )}
                <div
                  className={styles.productScroll}
                  style={{ display: "flex", overflowX: "scroll" }}
                  ref={scrollRef1}
                >
                  {popularInNetwork.map((product) => (
                    <button key={product.product_id} className={styles.box}>
                      <h3 className={styles.boxTitle}>{product.name}</h3>
                      <Link to={`/products/${product.product_id}`}>
                        <img
                          src={
                            product.image_url ? product.image_url : unknownImg
                          }
                          alt={product.name}
                          className={styles.image}
                        />
                      </Link>
                    </button>
                  ))}
                  <button
                    onClick={() => {
                      setActiveTab("Explore");
                      setSortType("Popularity");
                      setSortOrder("DESC");
                      if (userId === null || userId < 1) {
                        setSelectedGroup("Everyone");
                      } else {
                        setSelectedGroup("Following");
                      }
                      setUnratedOnly(true);
                    }}
                    className={styles.box}
                  >
                    <div className={styles.seeAllText}>See All</div>
                    {/* FontAwesomeIcon can be replaced by any similar icon component */}
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className={styles.seeArrow}
                    />
                  </button>
                </div>
              </div>
            </div>
          )}

          {topRated.length > 0 && (
            <div className={styles.contentContainer}>
              <h2 className={styles.contentHeader}>Top Rated All Time</h2>
              {topRated.length > 4 && leftArrowVisible2 && (
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  color="white"
                  onClick={() => scroll(-600, 2)}
                  className={styles.scrollLeft}
                />
              )}
              {topRated.length > 4 && rightArrowVisible2 && (
                <FontAwesomeIcon
                  icon={faArrowRight}
                  color="white"
                  onClick={() => scroll(600, 2)}
                  className={styles.scrollRight}
                />
              )}
              <div
                className={styles.productScroll}
                style={{ display: "flex", overflowX: "scroll" }}
                ref={scrollRef2}
              >
                {topRated.map((product) => (
                  <button key={product.product_id} className={styles.box}>
                    <h3 className={styles.boxTitle}>{product.name}</h3>
                    <Link to={`/products/${product.product_id}`}>
                      <img
                        src={product.image_url ? product.image_url : unknownImg}
                        alt={product.name}
                        className={styles.image}
                      />
                    </Link>
                  </button>
                ))}
                <button
                  onClick={() => {
                    setActiveTab("Explore");
                    setSortType("Rating");
                    setSortOrder("DESC");
                    setSelectedGroup("Everyone");
                  }}
                  className={styles.allBox}
                >
                  <div className={styles.seeAllText}>See All</div>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className={styles.seeArrow}
                  />
                </button>
              </div>
            </div>
          )}

          {featuredProducts.length > 0 && (
            <div className={styles.contentContainer}>
              <h2 className={styles.contentHeader}>Featured Products</h2>
              {featuredProducts.length > 4 && leftArrowVisible3 && (
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  color="white"
                  onClick={() => scroll(-600, 3)}
                  className={styles.scrollLeft}
                />
              )}
              {featuredProducts.length > 4 && rightArrowVisible3 && (
                <FontAwesomeIcon
                  icon={faArrowRight}
                  color="white"
                  onClick={() => scroll(600, 3)}
                  className={styles.scrollRight}
                />
              )}
              <div
                className={styles.productScroll}
                style={{ display: "flex", overflowX: "scroll" }}
                ref={scrollRef3}
              >
                {featuredProducts.map((product) => (
                  <div key={product.product_id} className={styles.box}>
                    <h3 className={styles.boxTitle}>{product.name}</h3>
                    <Link to={`/products/${product.product_id}`}>
                      <img
                        src={product.image_url ? product.image_url : unknownImg}
                        alt={product.name}
                        className={styles.image}
                      />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          )}

          {featuredUsers.length > 0 && (
            <div className={styles.contentContainer}>
              <h2 className={styles.contentHeader}>Rater Spotlight</h2>
              {featuredUsers.length > 4 && leftArrowVisible4 && (
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  color="white"
                  onClick={() => scroll(-600, 4)}
                  className={styles.scrollLeft}
                />
              )}
              {featuredUsers.length > 4 && rightArrowVisible4 && (
                <FontAwesomeIcon
                  icon={faArrowRight}
                  color="white"
                  onClick={() => scroll(600, 4)}
                  className={styles.scrollRight}
                />
              )}
              <div
                className={styles.productScroll}
                style={{ display: "flex", overflowX: "scroll" }}
                ref={scrollRef4}
              >
                {featuredUsers.map((user) => (
                  <button key={user.user_id} className={styles.box}>
                    <h3 className={styles.userTitle}>@{user.username}</h3>
                    <p className={styles.picksText}>
                      {user.rating_count} Ratings
                    </p>
                    <Link
                      to={
                        user.user_id === userId
                          ? "/MyProfile"
                          : `/profiles/${user.user_id}`
                      }
                    >
                      <img
                        src={user.photo_url ? user.photo_url : unknownImg}
                        alt={`@${user.username}`}
                        className={styles.userImage}
                      />
                    </Link>
                    <p className={styles.bioText}>{user.top_product_name}</p>
                    {user.similarity > 0 && (
                      <p className={styles.similarityText}>
                        Similarity: {user.similarity}%
                      </p>
                    )}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CategoryPage;
