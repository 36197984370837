import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import ReactSlider from "react-slider";
import styles from "./ExploreUsers.module.css";
import Search from "../components/Search2";
import { Link } from "react-router-dom";
import { UserContext } from "../components/UserContext";
import { FaTimes } from "react-icons/fa"; // make sure to import FaTimes
import { FaCheck } from "react-icons/fa";
import SimpleDropdown from "../components/SimpleDropdown";
import SimpleDropdownS from "../components/SimpleDropdownShort";
import { set } from "date-fns";
import CategoryDropdown from "../components/CategoryDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import Tutorial from "../components/Tutorial";

function ExploreUsers() {
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filters, setFilters] = useState([]);
  const [tags, setTags] = useState([]);
  const { userId, setShowLoginPopup, ratingCount, followingCount, setFollowingCount, username } = useContext(UserContext);
  const [selectedGroup, setSelectedGroup] = useState("Everyone");
  const [selectedCategory, setSelectedCategory] = useState("None");
  const [ratingRange, setRatingRange] = useState([1, 10]);
  const [ratingRangeDisplay, setRatingRangeDisplay] = useState([1, 10]);
  const [query, setQuery] = useState("");
  const [products, setProducts] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const disallowedQueries = ["The", "the", "The ", "the "];
  const [filterProductId, setFilterProductId] = useState(null);
  const [sortType, setSortType] = useState("Ratings");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [usernameInput, setUsernameInput] = useState("");
  const [minCommon, setMinCommon] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const [showFilter, setShowFilter] = useState(false);
  const location = useLocation();
  const [sortOptions, setSortOptions] = useState([
    "Similarity",
    "Ratings",
    "Mutual",
    "Followers",
  ]);
  const [showTutorial, setShowTutorial] = useState(
    location.state?.tutorial || false
  );
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const handleShareToApps = () => {
    const profileUrl = `https://www.rated.fun/Welcome/${username}`;
    navigator.clipboard
      .writeText(profileUrl)
      .then(() => {
        setShowCopyMessage(true);
        setTimeout(() => setShowCopyMessage(false), 2000); // Message disappears after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const scrollViewRef = useRef(null);
  const scrollToTop = () => {
    // scrollViewRef.current?.scrollIntoView({ behavior: "smooth" });
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    // Scroll to the top of the page when location changes
    scrollToTop();
  }, [location]);

  // Function to filter products based on input. Assumes `products` is available in scope.
  const filterProducts = (input) => {
    if (!input) {
      setFilteredProducts([]); // Optionally clear or reset the filtered list when there's no input
    } else {
      const filteredProducts = products.filter((product) =>
        product.name.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredProducts(filteredProducts);
    }
  };

  const handleFollow = async (profileId) => {
    if (!userId) {
      setShowLoginPopup(true);
      return;
    }

    setFollowingCount(followingCount + 1)
    // Update the local state first to reflect the change optimistically
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.user_id === profileId ? { ...user, is_following: true } : user
      )
    );

    // Define the payload
    const payload = {
      user_id: userId,
      profile_id: profileId,
      relationship: "Following",
    };

    // Send the POST request
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/add_usertag`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      // Check if the POST request was successful
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // You may want to do something with the response here
      const data = await response.json();
      console.log(data); // For debugging

      // Optionally, you could confirm the change happened on the backend
      // and update the state again if necessary
    } catch (error) {
      console.error("Failed to send follow", error);

      // If the POST failed, revert the optimistic update
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.user_id === profileId ? { ...user, is_following: false } : user
        )
      );
    }
  };

  const handleUnfollow = async (profileId) => {
    if (!userId) {
      setShowLoginPopup(true);
      return;
    }
    setFollowingCount(followingCount - 1)
    // Update the local state first to reflect the change optimistically
    const updatedUsers = users.map((user) => {
      if (user.user_id === profileId) {
        return {
          ...user,
          is_following: false, // Update the user_rating.
        };
      }
      return user; // Leave all other products unchanged.
    });

    // Update the state with the modified products array.
    setUsers(updatedUsers);

    // Define the payload
    const payload = {
      user1Id: userId,
      user2Id: profileId,
      relationship: "Following",
    };

    // Send the POST request
    try {
      const response = await fetch(`${apiUrl}/remove_group`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      // Check if the POST request was successful
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Optionally, you could confirm the change happened on the backend
      // and update the state again if necessary
    } catch (error) {
      console.error("Failed to unfollow", error);
    }
  };

  const handleRemove = async (profileId) => {
    if (!userId) {
      setShowLoginPopup(true);
      return;
    }
    //Remove user from list
    const updatedUsers = users.filter((user) => user.user_id !== profileId);
    setUsers(updatedUsers);

    // Define the payload
    const payload = {
      user_id: userId,
      profile_id: profileId,
    };

    // Send the POST request
    try {
      const response = await fetch(`${apiUrl}/skip_user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      // Check if the POST request was successful
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error("Failed to remove user", error);
    }
  };

  const addFilter = useCallback(
    (product) => {
      const newFilter = {
        productId: product.product_id,
        productName: product.name,
        minRating: ratingRange[0],
        maxRating: ratingRange[1],
      };
      setFilters([...filters, newFilter]);
      setQuery("");
    },
    [filters, ratingRange]
  );

  // Corrected function name to match the usage
  const removeFilter = useCallback(
    (filterToRemove) => {
      setFilters(
        filters.filter(
          (filter) => filter.productId !== filterToRemove.productId
        )
      );
    },
    [filters]
  );

  useEffect(() => {
    async function fetchUsers() {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_BACKEND_URL
          }/filter_buddy?filters=${JSON.stringify(
            filters
          )}&user_id=${userId}&relationship=${selectedGroup}&sort_type=${sortType}&sort_direction=${sortOrder}&username_input=${usernameInput}&min_common_ratings=${minCommon}&category_id=${selectedCategory}`,
          {
            method: "GET",
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchUsers();
  }, [
    filters,
    selectedGroup,
    selectedCategory,
    sortType,
    sortOrder,
    usernameInput,
    minCommon,
  ]);

  const resetFilters = () => {
    setSelectedCategory("None");
    // setSelectedGroup('Discover');
    setUsernameInput("");
    setFilters([]);
  };

  // Fetch products based on the query and category
  useEffect(() => {
    async function fetchProducts() {
      const url = `${apiUrl}/get_rated_products?userId=${userId}`;
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const responseData = await response.json();
        setProducts(responseData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    }
    fetchProducts();
  }, []);

  useEffect(() => {
    async function fetchCategories() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_all_categories`
        );
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    }

    fetchCategories();
  }, []); // Empty dependency array, only run once on mount.

  useEffect(() => {
    async function fetchTags() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/get_new_tags?userId=${userId}`
        );
        const data = await response.json();
        setTags(["Discover", "Everyone", ...data]);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    }

    fetchTags();
  }, []); // Empty dependency array, only run once on mount.

  const handleSelect = (option) => {
    setSortType(option);
  };

  return (
    <div className={styles.container}>
      {showTutorial && (
        <Tutorial screen="Users" onClose={() => setShowTutorial(false)} />
      )}
      <div
        className={styles.filterContainer}
        onClick={() => {
          scrollToTop();
          setShowFilter(!showFilter);
        }}
      >
        <FontAwesomeIcon icon={faFilter} className={styles.filterButton} />
      </div>
      <div className={styles.inviteContainer} onClick={handleShareToApps}>
        <FontAwesomeIcon icon={faUserPlus} className={styles.addUserButton} />
        <p className={styles.inviteText}>Invite Friends</p>
      </div>
      {showCopyMessage && (
        <p className={styles.copyMessage}>Link copied to clipboard!</p>
      )}

      <div className={styles.top_filters} ref={scrollViewRef}>
        <input
          className={styles.searchInput}
          placeholder="Search users"
          value={usernameInput}
          onChange={(e) => setUsernameInput(e.target.value)}
        />
        <div className={styles.dropdownUser}>
          <SimpleDropdownS
            options={tags}
            selectedOption={selectedGroup}
            onSelect={setSelectedGroup}
            name="Users"
          />
        </div>

        <SimpleDropdown
          options={sortOptions}
          selectedOption={sortType}
          onSelect={handleSelect}
          name="Sort Type"
        />
        <button
          className={styles.sortButton}
          onClick={() => setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC")}
        >
          {sortOrder === "ASC" ? "↑" : "↓"}
        </button>
      </div>
      {showFilter && (
        <div className={styles.all_filters}>
          <div className={styles.header_filters}>
            <CategoryDropdown
              categories={categories}
              selectedCategory={selectedCategory}
              onCategoryChange={setSelectedCategory}
              icon={true}
              name={"Category"}
            />

            <input
              className={styles.min_raters_input}
              type="number"
              placeholder="Min Mutual Ratings"
              onChange={(e) => {
                const value = parseInt(e.target.value, 10);
                if (!isNaN(value)) {
                  setMinCommon(value);
                } else {
                  setMinCommon("");
                }
              }}
              onKeyPress={(e) => {
                // Prevent default if not a number or if key is the dot (for decimal numbers)
                if (!/[0-9]/.test(e.key) || e.key === ".") {
                  e.preventDefault();
                }
              }}
              step="1"
              min="0"
            />
          </div>

          <div className={styles.bottom_row_filters}>
            <div className={styles.dropdown_container}>
              <input
                className={styles.product_search_input}
                type="text"
                placeholder="Search product filters"
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                  filterProducts(e.target.value);
                  setIsDropdownVisible(true);
                }}
                onFocus={() => setIsDropdownVisible(true)}
                onBlur={() => {
                  // Delay hiding the dropdown to allow interaction with its items
                  setTimeout(() => setIsDropdownVisible(false), 100);
                }}
              />
              {isDropdownVisible && query.length > 0 && (
                <ul className={styles.dropdown}>
                  {filteredProducts.map((product) => (
                    <li
                      key={product.product_id}
                      onClick={() => {
                        setIsDropdownVisible(false);
                        setQuery(product.name);
                        setFilterProductId(product.product_id);
                      }}
                    >
                      <div className={styles.search_details}>
                        <div className={styles.search_details_name}>
                          {product.name} ({product.secondary_info})
                        </div>
                        <div className={styles.category_image_container}>
                          <img
                            src={product.category_icon_url}
                            alt={product.category_name}
                            className={styles.category_icon}
                          />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className={styles.sliderContainer}>
              <span className={styles.slider_label}>
                Rating: {""}
                {ratingRangeDisplay[0]} - {ratingRangeDisplay[1]}{" "}
              </span>
              <ReactSlider
                className={styles.horizontal_slider}
                thumbClassName={styles.thumb}
                trackClassName={styles.track}
                defaultValue={[1, 10]}
                min={1}
                max={10}
                step={1}
                pearling
                onAfterChange={(values) => {
                  setRatingRange(values);
                }}
                onChange={(values) => {
                  setRatingRangeDisplay(values);
                }}
              />
            </div>

            <button
              className={styles.add_filter_button}
              onClick={() => {
                if (filterProductId) {
                  const productToAdd = products.find(
                    (p) => p.product_id === filterProductId
                  );
                  if (productToAdd) addFilter(productToAdd);
                }
              }}
            >
              Add Filter
            </button>
          </div>

          <p className={styles.note}>
            Note: You can only filter based on products you have rated.
          </p>

          <p className={styles.resetFilter} onClick={() => resetFilters()}>
            Reset Filters
          </p>
        </div>
      )}

      {filters.length > 0 && (
        <div className={styles.filters_list}>
          {filters.map((filter, index) => (
            <span className={styles.keyword_bubble} key={index}>
              {" "}
              {/* Use a unique key, product_id could repeat */}
              {filter.productName} : {filter.minRating}-{filter.maxRating}
              <FaTimes
                className={styles.bubble_button}
                style={{ cursor: "pointer", marginLeft: "5px" }}
                onClick={() => removeFilter(filter)}
              />
            </span>
          ))}
        </div>
      )}

      {ratingCount>=10 ? (
        <div></div>
      ) : (
        <div className={styles.noUsers}>
          {userId ? (
          <p>
            Once you have 10 ratings in common with a user, a similarity score
            will be calculated. Give some ratings, come back and see who is the
            most similar to you!
          </p>
        ) : (
          null
        )}
        </div>
      )}

      <div className={styles.gallery}>
        {users.map((user, index) => (
          <div
            key={user.username}
            className={styles.gallery_card}
            data-rank={index + 1}
          >
            <div className={styles.cardTop}>
              <Link to={`/profiles/${user.user_id}`}>
                <img
                  src={user.photo_url}
                  alt="User"
                  className={styles.userImage}
                />
              </Link>

              <div className={styles.cardMiddle}>
                <h4 className={styles.username}>@{user.username}</h4>{" "}
                {user.common_ratings > 0 ? (
                  <p className={styles.userInfo}>
                    Ratings: {user.rating_count} ({user.common_ratings} Mutual){" "}
                    <br /> Average Rating: {user.average_rating}
                  </p>
                ) : (
                  <p className={styles.userInfo}>
                    Ratings: {user.rating_count} <br /> Average Rating:{" "}
                    {user.average_rating}
                  </p>
                )}
              </div>

              <div className={styles.cardRight}>
                <h3 className={styles.similarity} title="Similarity Score">
                  {user.similarity > 0 ? `${user.similarity}%` : "?"}
                </h3>
                <h3 className={styles.similarityText}>Similarity</h3>
              </div>
            </div>
            <div className={styles.cardRelations}>
              {user.is_following ? (
                <button
                  className={styles.skip_container}
                  onClick={() => handleUnfollow(user.user_id)}
                >
                  <span className={styles.friendsText}>Unfollow</span>
                  {/* Uncomment and use appropriate icon library for web, e.g., react-icons */}
                  {/* <FontAwesomeIcon icon={faCheck} className={styles.tick} size={20} /> */}
                </button>
              ) : (
                <button
                  className={styles.skip_container}
                  onClick={() => handleRemove(user.user_id)}
                >
                  <span className={styles.friendsText}>Remove</span>
                </button>
              )}
              {user.is_following && user.follows_you ? (
                <div className={styles.friends_container}>
                  <span className={styles.friendsText}>Friends</span>
                </div>
              ) : user.is_following ? (
                <div className={styles.friends_container}>
                  <span className={styles.friendsText}>Following</span>
                </div>
              ) : (
                <button
                  className={styles.friends_container}
                  onClick={() => handleFollow(user.user_id)}
                >
                  <span className={styles.friendsText}>Follow</span>
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ExploreUsers;
